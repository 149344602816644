import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import Portal from "./Portal";

const AlertBody = ({ title, buttons, func, className, children }) => {

  const [visible, setVisible] = useState(true);
  const alert = useRef(null);

  const childrens =
    typeof children !== 'undefined' ? children : <span>undefined children</span>;

  useEffect(() => {
    addEvents();
  }, []);

  const addEvents = () => {
    ['click', 'touchend', 'keyup'].forEach(event =>
      document.addEventListener(event, handler, true)
    )
  };

  const removeEvents = () => {
    ['click', 'touchend', 'keyup'].forEach(event =>
      document.removeEventListener(event, handler, true)
    )
  };

  const handler = (event) => {
    if(alert)
      if(!alert.current.contains(event.target)) {
        toggle();
        removeEvents();
      }
    else
      removeEvents();      
  };

  const toggle = () => {
    removeEvents();
    setVisible(false);

    setTimeout(() => {
      func();
    }, 150);
  }

  return (
    <Portal>
      <div className={`alert flex flex-c flex-jc alert-backdrop${className}`}>
        <div ref={alert} className={`alert-container rd${visible ? ' alert-show': ' alert-hide'}`}>
          <div className="alert-icon alert-warning">
            <span className="alert-content">!</span>
          </div>
          <h2 className="alert-title">{title ? title : ''}</h2>
          <div className="alert-txt txt-light">{childrens}</div>
          <div className="alert-actions">
            {buttons ? buttons : <button onClick={toggle} type="button" className="btn btn-primary">Ok</button>}
          </div>
        </div>
      </div>
    </Portal>
  )
}

AlertBody.propTypes = {
  func: PropTypes.func.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.node
}

export default AlertBody