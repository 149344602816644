import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import Portal from "./Portal";

const Alert = ({ text, icon, title, buttons, func, className }) => {

  const [visible, setVisible] = useState(true);
  const alert = useRef(null);

  useEffect(() => {
    addEvents();
  }, []);

  const addEvents = () => {
    ['click', 'touchend', 'keyup'].forEach(event =>
      document.addEventListener(event, handler, true)
    )
  };

  const removeEvents = () => {
    ['click', 'touchend', 'keyup'].forEach(event =>
      document.removeEventListener(event, handler, true)
    )
  };

  const handler = (event) => {
    if(alert)
      if(!alert.current.contains(event.target)) {
        toggle();
        removeEvents();
      }
    else
      removeEvents();      
  };

  const toggle = () => {
    removeEvents();
    setVisible(false);

    setTimeout(() => {
      func();
    }, 150);
  }

  const rendericon = () => {
    if(icon === 'success') {
      return (
        <>
          <div className="alert-success-circular-line-left" style={{ backgroundColor: '#ffffff'}}></div>
          <span className="alert-success-line-tip"></span>
          <span className="alert-success-line-long"></span>
          <div className="alert-success-ring"></div>
          <div className="alert-success-fix" style={{ backgroundColor: '#ffffff'}}></div>
          <div className="alert-success-circular-line-right" style={{ backgroundColor: '#ffffff'}}></div>
        </>
      )
    }
    else if(icon === 'error') {
      return (
        <span className="alert-x-mark">
          <span className="alert-x-mark-line-left"></span>
          <span className="alert-x-mark-line-right"></span>
        </span>
      )
    }
    else if(icon === 'warning') {
      return (
        <span className="alert-content">!</span>
      )
    }
  }

  return (
    <Portal>
      <div className={`alert flex flex-c flex-jc alert-backdrop${className}`}>
        <div ref={alert} className={`alert-container rd${visible ? ' alert-show': ' alert-hide'}`}>
          <div className={`alert-icon alert-${icon}`}>
            {rendericon()}
          </div>
          <h2 className="alert-title">{title ? title : icon.charAt(0).toUpperCase() + icon.slice(1)}</h2>
          <div className="alert-txt txt-light">{text}</div>
          <div className="alert-actions">
            {buttons ? buttons : <button onClick={toggle} type="button" className="btn btn-primary">Ok</button>}
          </div>
        </div>
      </div>
    </Portal>
  )
}

Alert.propTypes = {
  func: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.node
}

export default Alert