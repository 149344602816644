import { useState } from 'react'
import { Alert, Footer, Header } from '../Components'

import img from "../Assets/conatctus.jpg";
import useForm from '../Utils/Hooks/useForm';

import jwt from '../Auth/jwt'

const ContactUs = () => {

  const [fileError, setFileError] = useState('');
  const [alert, setAlert] = useState(false);
  
  const defaultValues = {
    name: '',
    email: '',
    order_number: '',
    registration: '',
    subject: '',
    message: '',
    image: '',
    new_customer: 1,
  };

  const [image, setImage] = useState(defaultValues.image)

  const addCart = () => {
    if(values.image === defaultValues.image)
      delete values.image;

    jwt.post('/contact', values, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      if(res.status === 201) {
        setAlert(true);
        setValues(defaultValues);
      }
      else if(res.status === 203) {
        if(Object.keys(res.data.errors).length) {
          handleErrors(res.data.errors);
        }
      }
    })
    .catch(err => {
      if(err.response.status === 422)
        handleErrors(err.response.data.errors);
      else
        console.log(err);
    })
  }

  const handleRadioChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked ? '1' : '0' });
  }

  const { handleChange, handleSubmit, setValues, values, setErrors, errors, handleErrors } = 
  useForm( addCart, defaultValues, [ 'name', 'email', 'subject', 'message' ]);

  const onChange = e => {
    const reader = new FileReader(), file = e.target.files[0];
    if (file && file.type.split('/')[0] === 'image') {
      reader.onload = function () {
        setValues({ ...values, image: file});
        setImage(file.name);
      }
      reader.readAsDataURL(file);
      setFileError('');
    }
    else {
      setFileError('File is not of type Image.');
    }
  };

  return (
    <div className="wrapper">
      {alert &&
        <Alert icon="success" text="Contact added successfully." func={() => setAlert(false)} />
      }
      <div className="view-container">
        <Header />
        <div className="contact-us">
          <div className="flex mb-2">
            <div className="col">
              <div className="title-contact">
                <h3 className="m-1">Contact us:</h3>
              </div>
              <div className="flex-w">
                <div className="col-5">
                  <div className="flex flex-jc flex-c">
                    <form className='form contact-form' onSubmit={handleSubmit} >
                      <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                          className={`form-control${errors.name ? ' invalid' : ''}`}
                          value={values.name}
                          onChange={handleChange}
                          type="text"
                          name="name"
                          id="name"
                          placeholder="*Your Name"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                          className={`form-control${errors.email ? ' invalid' : ''}`}
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          name="email"
                          id="email"
                          placeholder="*Your Email...."
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="order_number">Your Order Number:</label>
                        <input
                          className={`form-control${errors.order_number ? ' invalid' : ''}`}
                          value={values.order_number}
                          onChange={handleChange}
                          type="text"
                          name="order_number"
                          id="order_number"
                          placeholder="Your Order Number"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="registration">Your Registration:</label>
                        <input
                          className={`form-control${errors.registration ? ' invalid' : ''}`}
                          style={{ textTransform: 'uppercase' }}
                          value={values.registration}
                          onChange={handleChange}
                          type="text"
                          name="registration"
                          id="registration"
                          placeholder="YOUR REG"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="subject">Subject:</label>
                        <input
                          className={`form-control${errors.subject ? ' invalid' : ''}`}
                          value={values.subject}
                          onChange={handleChange}
                          type="text"
                          name="subject"
                          id="subject"
                          placeholder="*Subject"
                        />
                      </div>
                      <div className="form-group addr">
                        <label htmlFor="message">Message:</label>
                        <textarea
                          className={`form-control${errors.message ? ' invalid' : ''}`}
                          name="message"
                          onChange={handleChange}
                          value={values.message}
                          id="message"
                          placeholder="*Message ...">
                        </textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="image">Image:</label>
                        <div className="form-image mt-1">
                          <div className="flex mb-1">
                            <label className="btn btn-primary">
                              Upload
                              <input hidden type="file" onChange={onChange} accept="image/*" />
                            </label>
                            <button className="btn btn-sm btn-border" type="reset"  
                              onClick={() => {
                                setValues({ ...values, image: defaultValues.image});
                              }}>
                              Remove
                            </button>
                          </div>
                          <div className="errors">
                            {image}
                            {fileError && <span className="error-msg">{fileError}</span>}
                            {errors.image && <span className="error-msg">{errors.image}</span>}
                            <p>Allowed JPG, JPEG or PNG. Max size of 800kB</p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="new_customer">New Customer?</label>
                        <input
                          className="check-control"
                          checked={values.new_customer === '0' ? false : true}
                          onChange={handleRadioChange}
                          type="checkbox"
                          name="new_customer"
                          id="new_customer"
                        />
                      </div>
                      <button type="submit" className='btn btn-primary mt-1'>Send Inquiry</button>
                    </form>
                  </div>
                </div>
                <div className="col-5">
                  <div>
                    <p>Please use our contact form on the left to send your enquiry. We aim to reply back to all emails within 24-48 hours within working hours Monday-Friday. We do not reply to emails out of hours and if your enquiry is sent out of hours this may increase the time to reply to your enquiry.</p>
                    <strong><p className="m-1">Please note we are unable to amend or cancel orders once placed via our website, please ensure when ordering you double check your order is correct and complete.</p></strong>
                    <p>If you wish to contact us via post please see below:</p>
                    <strong><p className="m-1">Postal Address:</p></strong>
                    <p>
                      Plates for Cars Limited <br />
                      3-4 Trinity Court <br />
                      Fonthill Industrial Park <br />
                      Clondalkin <br />
                      Dublin 22 <br />
                      Ireland<br />
                    </p>
                    <strong><p className="m-1">Thanks for contacting Plates for Cars</p></strong>
                    <img src={img} alt="" className="w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default ContactUs