import { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { FiChevronDown, FiX } from "react-icons/fi";

const Select = ({ selValue, data, setValue, isClearable, placeholder, className, disabled }) => {

  // ** State
  const [isOpen, setIsOpen] = useState(false);
  const [scrollHeight, setScrollHeight] = useState("100vh");
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [active, setActive] = useState(selValue ? data.indexOf(selValue) : 0);

  const menuRef = useRef(null), toggleRef = useRef(null), inputRef = useRef(null), timeRef = useRef(null);

  const classes = typeof className !== 'undefined' ? ` ${className}` : "";

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    setScrollHeight(menuRef.current.scrollHeight >= 230 ? 230 : menuRef.current.scrollHeight);
  }, [menuRef, data]);

  const addEvents = () => {
    ['click', 'touchend'].forEach(event =>
      document.addEventListener(event, handler, true)
    );
    inputRef.current.addEventListener('keydown', Tabhandler, true);
  };
    
  const removeEvents = () => {
    ['click', 'touchend'].forEach(event =>
      document.removeEventListener(event, handler, true)
    );
    inputRef.current.removeEventListener('keydown', Tabhandler, true);
  };

  const Tabhandler = (event) => {
    if(event.keyCode === 9 || event.keyCode === 13)
    {
      // Add Delay so value can be set
      setTimeout(() => {
        setIsOpen(false);
        removeEvents();
      }, 5);
    }
  }

  const handler = (event) => {
    if(isClearable) {
      if(timeRef.current.contains(event.target))
        return;
    }

    if(!toggleRef.current.contains(event.target)) {
      setTimeout(() => {
        setIsOpen(false);        
      }, 5);
      
      setTimeout(() => {
        setInputValue("");
      }, 160);
    }
    
    removeEvents();
  };

  const toggleMenu = (e) => {

    if(isClearable) {
      if(timeRef.current.contains(e.target))
        return;
    }

    if(toggleRef.current.contains(e.target) && !isOpen) {
      addEvents();
    }
    
    setActive(selValue ? data.indexOf(selValue) : 0);
    setIsOpen(!isOpen);
    inputRef.current.focus();
  }

  const buttonClick = (e, text) => {
    if(isOpen) {
      e.preventDefault();
      setSelect(text);
    }
  }

  const onChange = (e) => {
    if(!isOpen) {
      toggleMenu(e);
    }

    setInputValue(e.target.value);
  }
  
  const keyDownHandler = (event) => {
    if(event.keyCode === 38) {
      if(isOpen)
        setActive(active > 0 ? active - 1 : search(data).length - 1);
    }
    else if(event.keyCode === 40) {
      if(isOpen)
        setActive(active < search(data).length - 1 ? active + 1 : 0);
      else if(event.altKey) {
        toggleMenu(event);
      }
    }
    else if(event.keyCode === 9 || event.keyCode === 13) {
      if(isOpen) {
        setSelect(search(data)[active] ? search(data)[active] : "");
      }
    }
    else if(event.keyCode === 46 && inputValue === '') {
      setSelect("");
      inputRef.current.focus();
    }
  }

  const clear = (e) => {
    e.preventDefault();
    setSelect("");
    inputRef.current.focus();
  }

  function search(data) {
    return data.filter((data) => data.toString().toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  function setSelect(val) {
    inputRef.current.blur();
    
    setTimeout(() => {
      setValue(val);
      setInputValue("");
      setActive(val ? data.indexOf(val) : 0);
    }, 165);
  }

  const placetext = typeof placeholder !== 'undefined' ? placeholder : "Select...";

  return (
    <div className="select">
      <div className={`select-container flex flex-c flex-sb radius${classes}${focused ? " active" : ""}${disabled ? ' disabled' : ''}`} ref={toggleRef} onClick={toggleMenu}>
        <div className="select-control">
          {(!inputValue && selValue) && <div className="select-value"><span>{selValue}</span></div>}
          <input
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            tabIndex="0"
            type="text"
            aria-autocomplete="list"
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-controls="select-custom"
            aria-owns="select-custom"
            role="combobox"
            ref={inputRef}
            value={inputValue}
            onChange={onChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={keyDownHandler}
            placeholder={selValue ? inputValue : placetext}
          />
        </div>
        {isClearable &&
          <div className="flex flex-c" ref={timeRef} onClick={clear}>
            {selValue &&
              <FiX className="select-icon txt-light" size="17"/>
            } 
            </div>
        }
        <div className="flex flex-c">
          <FiChevronDown className={`select-icon txt-light${isOpen ? " active" : ""}`} size="18"/>
        </div>
      </div>
      <ul
        ref={menuRef}
        className="drop-menu select-menu"
        style={isOpen ?
            { maxHeight: scrollHeight, visibility: "visible"} : 
            { maxHeight: 0, visibility: "hidden"}}
        id="select-custom"
        role="listbox"
      >
        {search(data).length > 0
          ? (search(data).map((text, index) => (
              <li key={text}
                onMouseEnter={() => setActive(index)}
              >
                <button
                  tabIndex="-1"
                  role="option"
                  onClick={(e) => buttonClick(e, text)}
                  value={text}
                  className={`select-btn${selValue === text ? ' active' : ''}${active === index ? ' focus' : ''}`}
                  aria-selected={selValue === text ? "true" : "false"}
                >
                  {text}
                </button>
              </li>
              ))
            )
          :
          (<li><p className="no-opt txt-light">No Options</p></li>)
          }
      </ul>
    </div>
  )
}

Select.propTypes = {
  setValue: PropTypes.func.isRequired,
  data: PropTypes.node.isRequired,
  setValueName: PropTypes.string,
  selValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  isClearable: PropTypes.bool,
  placeholder: PropTypes.string
}


export default Select