import { Footer, Header } from "../../Components"
import SaveEdit from "./SaveEdit"

const SaveEditBase = () => {
  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <SaveEdit />
        <Footer />
      </div>
    </div>
  )
}

export default SaveEditBase