import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { GoTriangleRight } from 'react-icons/go'

import CartItems from "../Cart/CartItems"
import { Spinner } from "../../Components"

import jwt from '../../Auth/jwt'

import img from "../../Assets/terms_icon.png";

import { IoMdListBox } from "react-icons/io"
import { CiWarning } from "react-icons/ci"

const Summary = ({ values, deliveries }) => {

  const navigate = useNavigate()
  const [cart, setCart] = useState(null)
  const [data, setData] = useState(null)

  const del = deliveries.find(item => item.service === values.delivery_type)

  useEffect(() => {
    jwt.get('/cart-check').then(res => {
      if(res.status === 200) {
        if(res.data.length <= 0) {
          navigate('/design-online')
        }
        jwt.get('/cart-summary').then(res => {
          if(res.status === 200) {
            setCart(res.data);
          }
        })
      }
    })
    jwt.get('/fields').then(res => {
      if(res.status === 200) {
        setData(res.data)
      }
    })
  }, [])

  // ** Function to extract total of all items
  const getTotal = ( para ) => {
    let total = 0;

    if(cart.plates.length > 0)
      cart.plates.map(item => total = total + (item.price_now * item.front_qty) + (item.price_now * item.rear_qty) );
    if(cart.kits.length > 0)
      cart.kits.map(item => total = total + (item.price * item.qty));
    if(cart.products.length > 0)
      cart.products.map(item => total = total + item.price);

    if(para === 'total') {
      total = total + (+del.shipping_charges);
    }

    return parseFloat(total).toFixed(2);
  }

  if(data === null || cart === null) return <Spinner message="Fetching Summary..." />;

  return (
    <div className="summary">
      <div className="details mb-2">
        <div className="details-header flex flex-c">
          <IoMdListBox /><span>Shipping Details:</span>
        </div>
        <div className="details-body">
          <div className="row">
            <div className="col-8">
              <div className="fields">
              {Object.keys(values).map(key => {
                if(key === 'delivery_type') {
                  return;
                }
                else if(key === "vat_number" && values[key] === '') {
                  return null
                }

                let str = key.replace('_', ' ');
                let newstr = str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

                return (
                  <div className="field flex-w" key={key} >
                    <div className="name">
                      <span>{newstr}:</span>
                    </div>
                    <div className={`values${key === 'registration' ? ' upper' : ''}`}>
                      {values[key] === false ? 'No' : values[key] }
                    </div>
                  </div>
                )
              })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {cart.plates.length > 0 ? cart.plates.map((item, index) => (
        <div key={index}>
          <div className="cart">
            <CartItems type="View" item={item} data={data} key={item.id} />
          </div>
        </div>
      )) : null }
      {cart.products.length > 0 ? cart.products.map((item, index) => (
        <div className="totals mb-2 rd" key={index}>
          <div className="flex w-100">
            <div className="t-head">
              <GoTriangleRight /><span>{item.product.name}</span>
            </div>
          </div>
          <div className="t-body flex flex-sb w-100">
            <div className="price flex flex-e flex-je pd w-100">
              <div className="fields pr-fields">
                <div className="field flex-w">
                  <div className="name"><span>Quantity</span></div>
                  <div className="values">{item.qty}</div>
                </div>
                <div className="field flex">
                  <div className="name"><span>Price Each</span></div>
                  <div className="values">£ {item.product.price_now}</div>
                </div>
                <div className="field flex">
                  <div className="name"><span>Price Total</span></div>
                  <div className="values">£ {item.price}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )) : null }
      {cart.kits.length > 0 ? cart.kits.map((item, index) => (
        <div className="totals mb-2 rd" key={index}>
          <div className="flex w-100">
            <div className="t-head">
              <GoTriangleRight /><span>{item.name}</span>
            </div>
          </div>
          <div className="t-body flex flex-sb w-100">
            <div className="price flex flex-e flex-je pd w-100">
              <div className="fields pr-fields">
                <div className="field flex-w">
                  <div className="name"><span>Quantity</span></div>
                  <div className="values">{item.qty}</div>
                </div>
                <div className="field flex">
                  <div className="name"><span>Price Each</span></div>
                  <div className="values">£ {item.price}</div>
                </div>
                <div className="field flex">
                  <div className="name"><span>Price Total</span></div>
                  <div className="values">£ {parseFloat(item.price * item.qty).toFixed(2)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )) : null }
      <div className="totals mb-2 rd">
        <div className="flex flex-je w-100">
          <div className="t-head">
            Totals
          </div>
        </div>
        <div className="t-body flex flex-sb w-100">
          <div className="price flex flex-e flex-je pd w-100">
            <div className="fields pr-fields">
              <div className="field flex-w">
                <div className="name"><span>Sub Total</span></div>
                <div className="values">£ {getTotal('sub')}</div>
              </div>
              <div className="field flex">
                <div className="name"><span>Delivery Option: {del.service}</span></div>
                <div className="values">£ {del.shipping_charges}</div>
              </div>
              <div className="field flex" style={{ color: 'yellow' }}>
                <div className="name"><span>Total</span></div>
                <div className="values">£ {getTotal('total')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="confirm-term mb-1 not-note">
        <div className="flex flex-c">
          <img src={img} alt="" width="40" height="40" className="mr-1" />
          <span>
            By confirming and completing your order you are agreeing to our <Link to="/cart">Terms & Conditions</Link> and <Link to="/cart">Privacy Policy</Link>.
          </span>
        </div>
      </div>
      <div className="confirm-term">
        <div className="flex flex-c">
          <CiWarning />
          <span>
            Please note we are unable to amend or cancel orders once they have been placed.
            Please check now and ensure that your number plates are correct and complete.
            If you need to make a change please click <Link to="/cart" >Here</Link>.
          </span>
        </div>
      </div>
    </div>
  )
}

export default Summary