import { Link } from "react-router-dom"
import logos from "../Assets/footer_logos.jpg"

const Footer = () => {
  return (
    <div className="footer">
      <div className="content mb-1">
        <div className="terms"><Link to="/" >Terms and Conditions</Link> - <Link to="">Privacy Policy</Link></div>
        <div className="copy-right"> <strong>Copyright &#169; Plates for Cars Limited 2023.</strong> All rights Reserved.</div>
        <div className="register">Registered in the Republic of Ireland: <strong>502649</strong>. VAT Number: <strong>IE9799538L</strong></div>
      </div>
    </div>
  )
}

export default Footer