import { useEffect, useState } from "react"

import { Spinner } from "../../Components"
import img from "../../Assets/kits-banner.jpg";

import jwt from '../../Auth/jwt'
import AccessoriesItem from "./AccessoriesItem";

const Accessories = ({ setEmpty }) => {

  const [data, setData] = useState(null)
  const [kits, setKits] = useState(null)

  useEffect(() => {
    jwt.get('/accessories').then(res => {
      if(res.status === 200) {
        setData(res.data)
      }
    })
    jwt.get('/kits').then(res => {
      if(res.status === 200) {
        setKits(res.data)
      }
    })
  }, [])

  useEffect(() => {
    jwt.get('/cart-check').then(res => {
      if(res.status === 200) {
        if(res.data.length > 0) {
          setEmpty(false)
        }
        else {
          setEmpty(true)
        }
      }
    })
  }, [kits])
  
  if(data === null || kits === null) return <Spinner message="Fetching Accessories..." />;

  return (
    <div className="kits">
      <div className="flex">
        <div className="title flex flex-c flex-se">
          <img src={img} alt="" className="kits-banner" />
          <p>Please scroll down to continue and see all of the accessories we offer...</p>
        </div>
      </div>
      <div className="accessories mt-1">
        {data.length > 0 ? data.map((item, index) => (
          <AccessoriesItem item={item} key={index} kits={kits} setKits={setKits} />
        ))
        : null}
      </div>
    </div>
  )
}

export default Accessories