import { Routes, Route } from 'react-router-dom';
import { DesignOnline, Home, CheckoutBase, CartBase, CartEditBase, NotFound, ContactUs, Payment, SaveEditBase, ShopBase, ProductBase } from '../Views';

const Wrapper = () => {
  return (
    <Routes>
      <Route path="/" element={ <Home /> } />
      <Route path="/design-online" element={ <DesignOnline /> } />
      <Route path="/design-online/edit/:slug" element={ <CartEditBase /> } />
      <Route path="/design-online/saved/edit/:name" element={ <SaveEditBase /> } />
      <Route path="/cart" element={ <CartBase /> } />
      <Route path="/shop" element={ <ShopBase /> } />
      <Route path="/shop/:id" element={ <ProductBase /> } />
      <Route path="/checkout" element={ <CheckoutBase /> } />
      <Route path="/contact-us" element={ <ContactUs /> } />
      <Route path="/payment/:id" element={ <Payment /> } />
      <Route path='/*' element={ <NotFound />} />
    </Routes>
  )
}

export default Wrapper