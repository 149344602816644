import { Select } from "../../Components";
import { CountryCodes } from "./CountryCodes";

const ShippingDetails = ({ handleChange, values, errors, setErrors, setValues, handleRadioChange }) => {
  
  return (
    <>
      <div className="cust-header">
        Shipping Details:
      </div>
      <div className="cust-body form m-1">
        <div className="flex">
          <div className="col-10">
            <div className="form-group">
              <label htmlFor="full_name">Full Name:</label>
              <input
                className={`form-control${errors.full_name ? ' invalid' : ''}`}
                value={values.full_name}
                onChange={handleChange}
                type="text"
                name="full_name"
                id="full_name"
                placeholder="Full Name..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="company">Company:</label>
              <input
                className={`form-control${errors.company ? ' invalid' : ''}`}
                value={values.company}
                onChange={handleChange}
                type="text"
                name="company"
                id="company"
                placeholder="Company..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                className={`form-control${errors.email ? ' invalid' : ''}`}
                value={values.email}
                onChange={handleChange}
                type="email"
                name="email"
                id="email"
                placeholder="Email here..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile:</label>
              <input
                className={`form-control input-num${errors.mobile ? ' invalid' : ''}`}
                value={values.mobile}
                onChange={handleChange}
                type="number"
                name="mobile"
                id="mobile"
                placeholder="Mobile here..."
                onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
              />
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-10">
            <div className="cust-billing mb-1">
              Shipping Address:
            </div>
          </div>
        </div>
        <div className="flex mt-1">
          <div className="col-10">
            <div className="form-group addr">
              <label htmlFor="address">Address:</label>
              <textarea
                className={`form-control${errors.address ? ' invalid' : ''}`}
                name="address"
                onChange={handleChange}
                value={values.address}
                id="address"
                placeholder="Address ...">
              </textarea>
            </div>
            <div className="form-group">
              <label htmlFor="city">Town/City:</label>
              <input
                className={`form-control${errors.city ? ' invalid' : ''}`}
                value={values.city}
                onChange={handleChange}
                type="text"
                name="city"
                id="city"
                placeholder="Your City..."
              />
            </div>
            <div className="form-group cust-select">
              <label>Country</label>
              <Select
                setValue={v => {
                  if(errors.country) setErrors({ ...errors, country: '' });
                  setValues({ ...values, country: v });
                }}
                selValue={values.country}
                data={CountryCodes.map(m => m.name)}
                placeholder="None"
                className={`${errors.country ? ' invalid' : ''}`}
                isClearable
              />
            </div>
            <div className="form-group">
              <label htmlFor="postcode">Postcode:</label>
              <input
                className={`form-control${errors.postcode ? ' invalid' : ''}`}
                value={values.postcode}
                onChange={handleChange}
                type="text"
                name="postcode"
                id="postcode"
                placeholder="Your Postcode..."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShippingDetails