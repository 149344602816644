import { Link } from "react-router-dom";
import { FaShoppingBasket } from "react-icons/fa";

const Header = () => {
  return (
    <div className="header">
      <div className="offers flex flex-se">
        <p>25% OFF All Number Plates!</p>
        <p>No Discount Code Needed!</p>
        <p>Get the Best Quality Number Plates!</p>
      </div>
      <nav>
        <ul className="nav-list flex-w">
          <li className="nav-link"><Link to="/">Home</Link></li>
          <li className="nav-link"><Link to="/design-online">Design Online</Link></li>
          <li className="nav-link"><Link to="/shop">Shop</Link></li>
          <li className="nav-link"><Link to="/contact-us">Contact Us</Link></li>
          <li className="nav-link"><Link to="/cart"><FaShoppingBasket size={20} /></Link></li>
        </ul>
      </nav>
    </div>
  )
}

export default Header