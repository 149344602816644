import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import jwt from '../../Auth/jwt';
import { Alert, Spinner } from '../../Components';

const StripeCheckout = ({ order }) => {

  const navigate = useNavigate()

  const [approved, setApproved] = useState(false)
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [value, setValue] = useState('')
  const [errdes, setErrdes] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(value === '') {
      setErrdes(true)
      return;
    }
    
    const cardElement = elements.getElement(CardElement)
    
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if(!error) {
      try {
        const { id } = paymentMethod;

        setProcessing(true);
        jwt.post(`/pay/stripe/${order.id}` , { id: id, amount: order.price, desc: value })
        .then(res => {
          setProcessing(false);
          if(res.status === 200) {
            setApproved(true)
          }
          else {
            setError(true)
          }
        })
        .catch(err => {
          setProcessing(false);
          setError(true)
        })

      } catch (error) {
        setProcessing(false);
        setError(true)
      }
    }
  }

  return (
    <>
      {processing ? <Spinner message="Processing Payment..." /> : (
        <>
          {approved &&
            <Alert icon="success" text="Payment Successful." func={() => {
              navigate('/design-online')
            }} />
          }
          {error &&
            <Alert icon="error" text="Error Payment was unsuccessfull." func={() => {
              setError(false);
            }} />
          }
          <form onSubmit={handleSubmit}>
            <CardElement />
            <div className="form-group addr">
              <textarea
                style={{ width: '100%', marginLeft: 0 }}
                className={`strip-desc form-control mt-2 mb-1${errdes ? ' invalid' : ''}`}
                name="message"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                id="description"
                placeholder="*Description ...">
              </textarea>
            </div>
            <button type="submit" className="btn btn-primary">Pay</button>
          </form>
        </>
      )}
    </>
  )
}

export default StripeCheckout