import jwt from '../../Auth/jwt';
import useForm from '../../Utils/Hooks/useForm';

const SaveForm = ({ setSaveditems, setSaveAlter, saveAlert, data }) => {

  const defaultValues = {
    name: '',
  };

  const addCart = () => {
    let val = { ...data, name: values.name }
    jwt.post('/savedesign/add', val).then(res => {
      if(res.status === 200) {
        console.log(res.data);
        setSaveditems(res.data);
      }
      else if(res.status === 203) {
        if(Object.keys(res.data.errors).length) {
          handleErrors(res.data.errors);
        }
      }
      setSaveAlter(false);
    }).catch(err => {
      if(err.response) {
        if(err.response.status === 422) {
          handleErrors(err.response.data.errors);
        }
        else
        console.log(err);
        setSaveAlter(false);
      }
    })
  }

  const { handleChange, handleSubmit, setValues, values, setErrors, errors, handleErrors } = 
  useForm( addCart, defaultValues, [ 'name' ]);

  if(saveAlert === false) return;

  return (
    <div className="alert flex flex-c flex-jc alert-backdrop static-popup">
      <div className="alert-container rd alert-show">
        <div className="alert-icon alert-warning">
          <span className="alert-content">!</span>
        </div>
        <h2 className="alert-title">Save your PFC Design</h2>
        <div className="alert-txt txt-light">Name your PFC design:</div>
        <div className="alert-actions">
          <div className="form save-form">
            <div className="form-group">
              <input
                className={`form-control${errors.name ? ' invalid' : ''}`}
                value={values.name}
                onChange={handleChange}
                type="text"
                name="name"
                id="name"
                placeholder="*Name"
              />
            </div>
          </div>
          <div className="flex flex-sb mt-2">
            <button onClick={() => setSaveAlter(false)} type="button" className="btn btn-primary">Cancel</button>
            <button onClick={handleSubmit} type="button" className="btn btn-primary">Ok</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SaveForm