import { NumberInput, Select } from "../../Components"

import img from "../../Assets/designer_options_badge.png";
import img1 from "../../Assets/designer_options_frontqty.png";
import img2 from "../../Assets/designer_options_rearqty.png";
import img3 from "../../Assets/designer-questionmark.png";

import { MdFormatSize, MdOutlineBorderStyle } from 'react-icons/md'
import { FaRoad } from 'react-icons/fa'
import { FiDownload, FiEdit } from "react-icons/fi"
import { GiResize } from "react-icons/gi"
import { BiColorFill } from "react-icons/bi"
import { useEffect, useState } from "react";
import AlertBody from "../../Components/PopUps/AlertBody";

const DesignerForm = ({
  handleSubmit, 
  handleChange, 
  values, 
  errors,
  setErrors,
  setValues,
  setBadge,
  setBColor,
  setFColor,
  data,
  selProduct
}) => {

  useEffect(() => {
    let front = [];
    data.front_sizes.map(size => {
      if(+size.product_id === selProduct.id) {
        front.push(size.name)
      }
    })
  
    let rear = [];
    data.rear_sizes.map(size => {
      if(+size.product_id === selProduct.id) {
        rear.push(size.name)
      }
    })

    let fonts = [];
    data.fonts.map(fnt => {
      if(+fnt.product_id === selProduct.id) {
        fonts.push(fnt.name)
      }
    })

    setValues({ ...values, 
      front_size: front.length > 0 ? front[0] : '',
      rear_size: rear.length > 0 ? rear[0] : '',
      badge: selProduct.hasBadge ? values.badge : '',
      border: selProduct.border_value === null ? values.border : '',
      footer_text: selProduct.hasFooterText ? values.footer_text : '',
      font: fonts.length > 0 ? fonts[0] : '',
    })
    setBColor(selProduct.border_value === null ? values.border : selProduct.border_value);

    const fnt = data.fonts.find(m => m.name === (fonts.length > 0 ? fonts[0] : values.font));
    if(fnt !== undefined)
      setFont(fnt, 'font-style', 'PlateFont');

  }, [selProduct])

  let front_sizes = [];
  data.front_sizes.map(size => {
    if(+size.product_id === selProduct.id) {
      front_sizes.push(size.name);
    }
  })

  let rear_sizes = [];
  data.rear_sizes.map(size => {
    if(+size.product_id === selProduct.id) {
      rear_sizes.push(size.name);
    }
  })

  let fonts = [];
  data.fonts.map(fnt => {
    if(fnt.product_id === selProduct.id) {
      fonts.push(fnt.name)
    }
  })

  const setFont = ( fnt, id, fontName ) => {
    // Get a reference to the current in-use stylesheet, if there is one.
    var fontStyleSheet = document.getElementById(id);

    if (fontStyleSheet) {
      fontStyleSheet.remove();
    }

    // Then define a new stylesheet with an updated @font-face rule:
    var newFontStyleSheet = document.createElement("style");
    newFontStyleSheet.id = id;
    newFontStyleSheet.textContent = `
      @font-face {
        font-family: '${fontName}';
        src: 
        url("${window.location.origin}/static/media/fonts/${fnt.file_woff}") format('woff'),
        url("${window.location.origin}/static/media/fonts/${fnt.file_woff2}") format('woff2'),
        url("${window.location.origin}/static/media/fonts/${fnt.file_ttf}") format("truetype")
        ${fnt.file_eot !== null ? 
          `,url("${window.location.origin}/static/media/fonts/${fnt.file_eot}") format("embedded-opentype"),`
        : '' }
        ${fnt.file_svg !== null ? 
          `url("${window.location.origin}/static/media/fonts/${fnt.file_svg}") format("svg");` :
          ';'}
      }
    `;

    // Then we swap: add the new rule first, then remove the old one.
    // That way you don't get a flash of unstyled text.
    document.head.appendChild(newFontStyleSheet);
  }

  useEffect(() => {
    const fnt = data.fonts.find(m => m.name === values.font);
    setFont(fnt, 'font-style', 'PlateFont');
  }, [])
  


  const [alert, setAlert] = useState({
    active: false,
    type: '',
  })

  const alertC = (e, text) => {
    e.preventDefault();
    setAlert({ active: true, type: text });
  }
  
  return (
    <>
      {alert.type === 'reg' && alert.active &&
        <AlertBody className=" details-alert" title="Registration Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
              The registration field is where you enter your vehicle registration number. Please ensure you enter the registration spacing as you require.
            </p><br />
            <p>
              We make your number plates with the exact spacing you enter online. For road legal spacing please enter one space in the correct location.
            </p><br />
            <p>
            On modern registrations one space only after the 4th digit is correct. Please also refer to your V5 log book or retention certificate if legal spacing is required.
            </p><br />
            <p>Below are some examples of common number plate registration formats:</p>
            <p>AB21 AAA<br />A123 AAA<br />A12 AAA<br />A1 AAA<br />A1 AA<br /></p>
          </div>
        </AlertBody>
      }
      {alert.type === 'front-size' && alert.active &&
        <AlertBody className=" details-alert" title="Front Size Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
              The Front Size option lets you choose from a selection of over 25 front number plate sizes. We have one of the best selections of number plate sizes and formats on the market.
            </p><br />
            <p>
              The vast majority of cars in the UK use the standard size number plate being:<br />
              520 x 111mm ( 20.5 x 4.4in ).
            </p><br />
            <p>
              A number of other vehicles such as 4x4’s, motorcycles, some range rovers and jaguars use different size number plates mainly for the rear size.
            </p><br />
            <p>Please ensure you check and measure what size you require before ordering.</p>
          </div>
        </AlertBody>
      }
      {alert.type === 'rear-size' && alert.active &&
        <AlertBody className=" details-alert" title="Rear Size Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
            The Rear Size option lets you choose from a selection of over 40 rear number plate sizes. We have one of the best selections of number plate sizes and formats on the market.
            </p><br />
            <p>
              The vast majority of cars in the UK use the standard size number plate being: <br />
              520 x 111mm ( 20.5 x 4.4in ).
            </p><br />
            <p>
              A number of other vehicles such as 4x4’s, motorcycles, some range rovers and jaguars use different size number plates mainly for the rear size.
            </p><br />
            <p>Please ensure you check and measure what size you require before ordering.</p>
          </div>
        </AlertBody>
      }
      {alert.type === 'footer' && alert.active &&
        <AlertBody className=" details-alert" title="Footer Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
              The footer section of the designer enables you to display text at the bottom of the number plates. You can enter for example a name, or slogan or your favourite quote or similar.
            </p><br />
            <p>
            You also have the option of changing the footer font and colour. We have over 20 style of fonts, and you can change the footer colour with a choice of over 20 colours.
            </p>
          </div>
        </AlertBody>
      }
      {alert.type === 'font' && alert.active &&
        <AlertBody className=" details-alert" title="Font Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
            The font section of the designer offers a wide range of both road legal and show/off road fonts for your number plates.
            </p><br />
            <p>
            The most popular being our UK Legal, UK Legal Black Gel Resin and our UK Legal Laser 4D all of which can be ordered via the menu on the left hand side.
            </p><br />
            <p>
            We also offer a large selection of older road legal fonts, motorcycle fonts, and fonts from other countries such as France, Germany, USA and so on.
            </p>
          </div>
        </AlertBody>
      }
      {alert.type === 'border' && alert.active &&
        <AlertBody className=" details-alert" title="Border Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
            The border section of the designer allows you to add a coachline around the edge of your number plates.
            </p><br />
            <p>
            You simply just select the colour you would like, the majority of customers choose black, but we also offer a wide range of colours.
            </p><br />
            <p>
            Remember we are one of the only suppliers who can offer full colour number plate printing, so colours will appear true even on rear plates!
            </p><br />
            <p>
            All border options are road legal and really compliment any number plate.
            </p>
          </div>
        </AlertBody>
      }
      {alert.type === 'badge' && alert.active &&
        <AlertBody className=" details-alert" title="Badge Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
            The badge section of the designer offers you the option to add a wide variety of badges to your number plates.
            </p><br />
            <p>
            The most popular and well known are the GB and UK options. We also offer a large number of other country/flag options such as England, Wales, Scotland etc as well as the vast majority of manufacturer badges and Green EV badges for electric vehicles.
            </p><br />
            <p>
            We also offer a send a badge via email option where you can send in any badge via email once your order is complete to add to your number plates.
            </p>
          </div>
        </AlertBody>
      }
      {alert.type === 'legal' && alert.active &&
        <AlertBody className=" details-alert" title="Legal Details Help" func={() => setAlert(false)}>
          <div className="info-details">
            <p>
            The Legal Details option on the designer is enabled as standard. The legal details option is a legal requirement for all number plates displayed within the UK.
            </p><br />
            <p>
            The Legal Details shows the number plate manufacturers name, postcode and the british standard number.
            </p><br />
            <p>
            For this reason the legal details are a default option, but you can deselect the Legal Details and nothing will be printed to your number plates if so required.
            </p><br />
            <p>
            The legal details are printed in small text at the bottom right hand corner in a light grey and conform to the new 2021 BSAU 145e standards.
            </p><br />
            <p>
            The Legal Details are not required for off road number plates, show plates and signs.
            </p>
          </div>
        </AlertBody>
      }
      <form className="design-form ml-1" onSubmit={handleSubmit}>
        <div className="flex-w">
          <div className="col-5">
            <div className="form-group">
              <label htmlFor="registration"><FiEdit size={17} /> Registration</label>
              <input
                className={`form-control${errors.registration ? ' invalid' : ''}`}
                style={{ textTransform: 'uppercase' }}
                value={values.registration}
                onChange={handleChange}
                type="text"
                name="registration"
                id="registration"
                placeholder="YOUR REG"
              />
              <button onClick={(e) => alertC(e, 'reg')} >
                <img src={img3} width="20" height="20" className="ml-1" />
              </button>
            </div>
            <div className="form-group">
              <label htmlFor="front_size"><GiResize size={17} /> Front Size</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    if(errors.front_size) setErrors({ ...errors, front_size: "" });
                    setValues({ ...values, front_size: v })
                  }}
                  selValue={values.front_size}
                  data={front_sizes}
                  disabled={values.front_qty > 0 ? false : true}
                  placeholder="None"
                  className={`${errors.front_size ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'front-size')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="rear_size"><GiResize size={17} color="yellow" /> Rear Size</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    if(errors.rear_size) setErrors({ ...errors, rear_size: "" });
                    setValues({ ...values, rear_size: v })
                  }}
                  selValue={values.rear_size}
                  data={rear_sizes}
                  disabled={values.rear_qty > 0 ? false : true}
                  placeholder="None"
                  className={`${errors.rear_size ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'rear-size')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="footer_text"><FiDownload size={17} /> Footer Text</label>
              <input
                className={`form-control${errors.footer_text ? ' invalid' : ''}`}
                value={values.footer_text}
                onChange={handleChange}
                disabled={selProduct.hasFooterText ? false : true}
                type="text"
                name="footer_text"
                id="footer_text"
              />
              <button onClick={(e) => alertC(e, 'footer')} >
                <img src={img3} width="20" height="20" className="ml-1" />
              </button>
            </div>
            <div className="form-group">
              <label htmlFor="footer_font"><MdFormatSize size={18} /> Footer Font</label>
              <Select
                setValue={v => {
                  const fnt = data.fonts.find(m => m.name === v);
                  setFont(fnt, 'font-footer', 'FooterFont');
                  document.fonts.ready.then(function () { // IF FONT LOADED
                    if(errors.footer_font) setErrors({ ...errors, footer_font: "" });
                    setValues({ ...values, footer_font: v })
                  });
                }}
                selValue={values.footer_font}
                data={data.footer_fonts.map(fnt => fnt.name)}
                placeholder="None"
                disabled={values.footer_text ? false : true}
                className={`${errors.footer_font ? ' invalid' : ''}`}
              />
            </div>
            <div className="form-group">
              <label htmlFor="footer_color"><BiColorFill size={20} /> Footer Color</label>
              <Select
                setValue={v => {
                  const clr = data.colors.find(m => m.name === v);

                  if(clr)
                    setFColor(clr.value);
                  else
                    setFColor('');

                  if(errors.footer_color) setErrors({ ...errors, footer_color: "" });
                  setValues({ ...values, footer_color: v })
                }}
                selValue={values.footer_color}
                data={data.colors.map(bdg => bdg.name)}
                placeholder="None"
                disabled={values.footer_text ? false : true}
                className={`${errors.footer_color ? ' invalid' : ''}`}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="form-group">
              <label htmlFor="font"><MdFormatSize size={18} /> Font</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    const fnt = data.fonts.find(m => m.name === v);
                    setFont(fnt, 'font-style', 'PlateFont');

                    document.fonts.ready.then(function () { // IF FONT LOADED
                      if(errors.font) setErrors({ ...errors, font: "" });
                      setValues({ ...values, font: v })
                    });
                  }}
                  selValue={values.font}
                  data={fonts}
                  placeholder="None"
                  className={`${errors.font ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'font')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="border"><MdOutlineBorderStyle size={18} /> Border</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    const clr = data.colors.find(m => m.name === v);

                    if(clr)
                      setBColor(clr.value);
                    else
                      setBColor('');

                    if(errors.border) setErrors({ ...errors, border: "" });
                    setValues({ ...values, border: v })
                  }}
                  selValue={values.border}
                  data={data.colors.map(bdg => bdg.name)}
                  disabled={selProduct.hasBorder ? false : true}
                  placeholder="No Border"
                  isClearable
                  className={`${errors.border ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'border')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="Badge"><img src={img} width="18" height="18" /> Badge</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    const badge = data.badges.find(m => m.name === v);

                    if(badge)
                      setBadge({ value: badge.name, image: badge.image });
                    else
                      setBadge({ value: '', image: null });

                    if(errors.badge) setErrors({ ...errors, badge: "" });
                    setValues({ ...values, badge: v })
                  }}
                  selValue={values.badge}
                  data={data.badges.map(bdg => bdg.name)}
                  disabled={selProduct.hasBadge ? false : true}
                  placeholder="No Badge"
                  isClearable
                  className={`${errors.badge ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'badge')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="front_qty"><img src={img1} width="18" height="18" /> Front QTY</label>
              <NumberInput
                setValue={v => setValues({ ...values, front_qty: +v(values.front_qty)})}
                value={values.front_qty}
                max={99}
                min={0}
              />
            </div>
            <div className="form-group">
              <label htmlFor="rear_qty"><img src={img2} width="18" height="18" /> Rear QTY</label>
              <NumberInput
                setValue={v => setValues({ ...values, rear_qty: +v(values.rear_qty)})}
                value={values.rear_qty}
                max={99}
                min={0}
              />
            </div>
            <div className="form-group">
              <label htmlFor="legal_details"><FaRoad size={18} /> Legal Details</label>
              <div className="flex-65">
                <Select
                  setValue={v => {
                    if(errors.legal_details) setErrors({ ...errors, legal_details: "" });
                    setValues({ ...values, legal_details: v })
                  }}
                  selValue={values.legal_details}
                  data={['Yes', 'No']}
                  disabled={selProduct.hasLegalDetails ? false : true}
                  placeholder="None"
                  className={`${errors.legal_details ? ' invalid' : ''}`}
                />
                <button onClick={(e) => alertC(e, 'legal')} >
                  <img src={img3} width="20" height="20" className="ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default DesignerForm