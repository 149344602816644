import { Footer, Header } from "../../Components"
import Shop from "./Shop"

const ShopBase = () => {
  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <Shop />
        <Footer />
      </div>
    </div>
  )
}

export default ShopBase