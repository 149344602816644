const NotFound = () => {
  return (
    <div className="page-not-found">
      <div>
        <div className="ntf">404</div>
        <div>PAGE NOT FOUND</div>
      </div>
    </div>
  )
}

export default NotFound