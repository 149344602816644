import { useEffect } from "react";
import ReactDOM from 'react-dom';

const Portal = ({ children }) => {
  const el = document.createElement('div');
  el.setAttribute('style', 'position: relative; z-index: 1050; display: block;');

  useEffect(() => {
    document.body.appendChild(el);

    return () => {
      if(el)
        document.body.removeChild(el);
    };
  }, [children]);

  return ReactDOM.createPortal(children, el);
}

export default Portal