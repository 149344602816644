import { useEffect, useState } from 'react'

import { PayPalButtons } from '@paypal/react-paypal-js'

import { FaClipboardList, FaCreditCard, FaShippingFast, FaShoppingCart, FaTools, FaUser } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'

import jwt from '../../Auth/jwt'
import { Alert, Footer, Header, Spinner } from '../../Components'
import StripeCheckout from './StripeCheckout'

const Payment = () => {

  const navigate = useNavigate()
  const { id } = useParams();

  const [paidFor, setPaidFor] = useState(false)
  const [order, setOrder] = useState(null)
  const [approve, setApprove] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    jwt.get(`/orders/${id}`).then(res => {
      if(res.status === 200) {
        if(res.data.is_paid) {
          console.log(res.data)
          setPaidFor(true);
        }
        else {
          setOrder(res.data)
        }
      }
      else if(res.status === 203) {
        navigate('/')
      }
      else {
        console.log(res)
      }
    })
  }, [])

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: 'This is a test product',
          amount: {
            value: order.price,
          },
        },
      ],
    });
  };
  
  const onApprove = async (data, actions) => {
    const payment = await actions.order.capture();
    console.log("order", payment)

    jwt.post(`/pay/paypal/${order.id}`, payment).then(res => {
      if(res.status === 200) {
        setApprove(true);
      }
    })
  };
  
  return (
    <div className="wrapper">
      {paidFor &&
        <Alert icon="success" text="You already paid for you order." func={() => {
          navigate('/design-online')
        }} />
      }
      {approve &&
        <Alert icon="success" text="Payment Successful." func={() => {
          navigate('/design-online')
        }} />
      }
      {canceled &&
        <Alert icon="error" text="Payment Cancelled." func={() => {
          setCanceled(false);
        }} />
      }
      {error &&
        <Alert icon="error" text="Error payment was unsuccessfull." func={() => {
          setError(false);
        }} />
      }
      <div className="view-container">
        <Header />
        {order === null ? <Spinner message="Fetching payment data..." /> : (
          <>
            <div className="checkout">
              <div className="container">
                <div className="steps">
                  <div className="flex-w mt-2">
                    <p className="step mb-2 active done"><FaShoppingCart /><span>Shopping cart</span></p>
                    <p className="step mb-2 done"><FaTools /><span>Accessories</span></p>
                    <p className="step mb-2 done"><FaUser /><span>Customer</span></p>
                    <p className="step mb-2 done"><FaShippingFast /><span>Delivery</span></p>
                    <p className="step mb-2 done"><FaClipboardList /><span>Summary</span></p>
                    <p className="step mb-2 active"><FaCreditCard /><span>Payment</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment">
              <div className="flex-w flex-jc mb-2">
                <div className="col-12">
                  <div className="paywith">
                    <div className="option">
                      <p className="mb-2">Pay with Paypal</p>
                      <PayPalButtons
                        createOrder={(data, actions) => createOrder(data, actions) }
                        onApprove={(data, actions) => onApprove(data, actions) }
                        onCancel={() => {
                          setCanceled(true)
                        }}
                        onError={(err) => {
                          setError(err)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="paywith">
                    <p className="mb-2">Pay with Stripe</p>
                    <StripeCheckout order={order} />
                  </div>
                </div>
              </div>
            </div>  
          </>
        )}
        <Footer />
      </div>
    </div>
  )
}

export default Payment