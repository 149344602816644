import { Footer, Header } from "../../Components"
import ItemPreview from "./ItemPreview"

const ProductBase = () => {
  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <ItemPreview />
        <Footer />
      </div>
    </div>
  )
}

export default ProductBase