import PropTypes from 'prop-types';
import Alert from './Alert';

const ConfirmAlert = ({ confirmTitle, alert, setAlert, handleSubmit, text, title, btntext, cbtntext, hideConfirm, classes }) => {

  const className = typeof classes !== 'undefined' ? `${classes}` : "";

  return (
    <>
      {alert.active && (
        alert.state === 'warning' ?
        <Alert
          className={className}
          icon="warning"
          title={confirmTitle === '' ? "Are you sure?" : confirmTitle}
          text={text.Confirm}
          func={() => setAlert({...alert, active: false })}
          buttons={
            <>
              <button className="btn btn-primary mr-1" onClick={(e) => {
                if(hideConfirm)
                  setAlert({ ...alert, active: false})

                handleSubmit(e)
              }}>{btntext}</button>
              <button className="btn btn-danger" 
                onClick={() => setAlert({...alert, active: false })}
              >
                {cbtntext === '' ? 'Cancel' : cbtntext}
              </button>
            </>
          }
        /> : hideConfirm ? null : alert.state === 'success' ?
        <Alert
          icon="success"
          title={title}
          text={text.Success}
          func={() => setAlert({ ...alert, active: false, state: 'warning' })}
        /> : alert.state === 'error' &&
        <Alert
          icon="error"
          title="Process Failed!"
          text={text.Error}
          func={() => setAlert({ ...alert, active: false, state: 'warning', error: '' })}
        />
      )}
    </>
  )
}

ConfirmAlert.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired,
  title: PropTypes.string,
  btntext: PropTypes.string.isRequired
}

export default ConfirmAlert