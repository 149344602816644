export const setFont = ( fnt, id, fontName ) => {
  // Get a reference to the current in-use stylesheet, if there is one.
  var fontStyleSheet = document.getElementById(id);

  if (fontStyleSheet) {
    fontStyleSheet.remove();
  }

  // Then define a new stylesheet with an updated @font-face rule:
  var newFontStyleSheet = document.createElement("style");
  newFontStyleSheet.id = id;
  newFontStyleSheet.textContent = `
    @font-face {
      font-family: '${fontName}';
      src: 
      url("${window.location.origin}/static/media/fonts/${fnt.file_woff}") format('woff'),
      url("${window.location.origin}/static/media/fonts/${fnt.file_woff2}") format('woff2'),
      url("${window.location.origin}/static/media/fonts/${fnt.file_ttf}") format("truetype")
      ${fnt.file_eot !== null ? 
        `,url("${window.location.origin}/static/media/fonts/${fnt.file_eot}") format("embedded-opentype"),`
      : '' }
      ${fnt.file_svg !== null ? 
        `url("${window.location.origin}/static/media/fonts/${fnt.file_svg}") format("svg");` :
        ';'}
    }
  `;

  // Then we swap: add the new rule first, then remove the old one.
  // That way you don't get a flash of unstyled text.
  document.head.appendChild(newFontStyleSheet);
}