import { useState } from 'react'
import { FiTrash } from 'react-icons/fi';
import { FiMinus, FiPlus } from "react-icons/fi";
import jwt from '../../Auth/jwt';

const AccessoryMultiple = ({ item, kits, setKits }) => {

  let q = kits.find(k => k.id === item.id)

  const setQuantity = () => {
    let qunatity = {};
    item.colors.map(clr => {
      if(q) {
        if(clr.name === q.color) {
          qunatity = { ...qunatity, [clr.id]: q.qty}
        }
        else {
          qunatity = { ...qunatity, [clr.id]: '1'}
        }
      }
      else {
        qunatity = { ...qunatity, [clr.id]: '1'}
      }
    });
    return qunatity;
  }

  const [qty, setQty] = useState(setQuantity())

  const addtoCart = (clr) => {
    jwt.post('/kit/Madd',
      { 
        id: item.id,
        name: item.name,
        color: clr.name,
        qty: +qty[clr.id],
        price: item.price * +qty[clr.id]
      }
    )
    .then(res => {
      if(res.status === 200) {
        setKits(res.data)
      }
    })
  }

  const removeKit = (clr) => {
    jwt.delete(`/kits/Mremove/${clr.name}`)
    .then(res => {
      if(res.status === 200) {
        setKits(res.data)
      }
    })
  }

  return (
    <>
      <div className="left flex-sb flex mr-2 mb-1">
        <div className="name mb-1">
          <p className="mb-1">Product</p>
          {item.colors.map((clr, index) => (
            <p className="item-name fix-lh" key={index} >{clr.name}</p>
          ))}
        </div>
        <div className="plus mb-1">
          <p className="mb-1">Actions</p>
          {item.colors.map((clr, index) => {

            let isincart = false;
            kits.find(k => {
              if(k.color === clr.name) {
                isincart = true;
              }
            });

            return (
              <div className="flex flex-je fix-lh" key={index}>
                <button 
                  className="btn btn-primary" 
                  onClick={() => addtoCart(clr)} >
                Add to Cart</button>
                {isincart ? <button className="btn btn-primary ml-1" onClick={() => removeKit(clr)} ><FiTrash /></button> : null}
              </div>
            )
            })}
        </div>
      </div>
      <div className="flex mb-1">
        <div className="qty">
          <p className="mb-1">Quantity</p>
          {item.colors.map((clr, index) => (
            <div className="fix-lh flex flex-c" key={index}>
              <div className="num-input-group flex flex-c radius">
                <button 
                  className="minus" 
                  onClick={(e) => {
                    e.preventDefault();
                    setQty(prev => { return {...prev, [clr.id]: prev[clr.id] > 1 ? (+prev[clr.id] - 1).toString() : prev[clr.id] }} )
                  }}
                ><FiMinus /></button>
                <input
                  className="form-control num-input"
                  value={qty[clr.id]}
                  onChange={(e) => {
                    setQty(prev => { return { ...prev, [clr.id]: e.target.value.toString() } })
                  }}
                  type="number"
                  placeholder="0"
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                />
                <button 
                  className="plus" 
                  onClick={(e) => {
                    e.preventDefault();
                    setQty(prev => { return {...prev, [clr.id]: prev[clr.id] < 100 ? (+prev[clr.id] + 1).toString() : prev[clr.id] }} )
                  }}
                ><FiPlus /></button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AccessoryMultiple