const Spinner = ({ message }) => {
  return (
    <div className="fallback-spinner flex flex-c flex-jc">
      <div className="loading mb-1">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
      {message && <p>{message}</p>}
    </div>
  )
}

export default Spinner