import about from "../Assets/aboutus_reviews_smaller.jpg";
import warning from "../Assets/numberplate_warning.jpg";

import img1 from "../Assets/standard_acrylic_small.jpg";
import img2 from "../Assets/black_gel_resin_small.jpg";
import img3 from "../Assets/carbon_gel_resin_small.jpg";
import img4 from "../Assets/laser_4d_small.jpg";
import img5 from "../Assets/standard_metal_small.jpg";
import img6 from "../Assets/vintage_metal_small.jpg";
import { Footer, Header } from "../Components";

const Home = () => {

  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <div className="welcome">
          <section>
            <div className="flex-w flex-sb m-2">
              <div className="col-1">
                <div className="side-content">
                  <div className="side-text">
                    <h1 className="text-heading">Welcome to Plates for Cars</h1>
                    <h3 className="mb-1">Europe's Leading Number Plate Supplier</h3>
                    <p>We have been established within the number plate industry for over two decades and have become recognised as one of the most trusted and reputable number plate manufacturers.
                      <br />
                      <br />
                      We base our success on the promise to deliver the best quality at an affordable price with fantastic customer service. We believe in the highest quality manufacturing methods, and use the best materials and the latest printing and manufacturing methods. This results in a unique and bespoke product that no other companies can offer.
                      <br />
                      <br />
                      Our goods are sourced within Europe rather than China so we can offer you the fastest delivery with all items in stock, and we can also ensure the quality is of the highest standards. We have built trusted relationships over the past decade with several large and reputable companies in the motor industry, who trust Plates for Cars to supply their number plates and products.
                      <br />
                      <br />
                      We supply number plates and number plate materials throughout the world but the majority are for a UK market. We also supply number plates to a number of European countries such as France, Ireland and Spain that also use acrylic and metal number plates.
                      <br />
                      <br />
                      We would like to thank you for visiting Plates for Cars and for taking time out of your day to find out more about our brand. If you have any questions please do not hesitate to contact us.
                    </p>
                  </div>

                  <div className="banner flex m-2">
                    <img src={about} alt="" className="img-fluid w-100" />
                  </div>

                  <div className="title mb-1">
                    <h1>Behind the Scenes at Plates for Cars...</h1>
                  </div>

                  <div className="banner flex flex-jc flex-c mb-2">
                    <iframe 
                      className="frame"
                      style={{ borderRadius: 10 }}
                      title="Scenes at Plates for Cars"
                      src="https://www.youtube.com/embed/cO1fDmMS8Rs?rel=0&amp;vq=hd1080"
                      allow="autoplay; encrypted-media" 
                      allowFullScreen=""
                    ></iframe>
                  </div>

                  <div className="title mb-1">
                    <h1>See Phil's Video from Revive My Rides YouTube Channel fitting our Quality UK Pressed Metal Number Plates...</h1>
                  </div>

                  <div className="banner flex flex-jc flex-c">
                    <iframe 
                      className="frame"
                      title="Revive"
                      style={{ borderRadius: 10 }}
                      src="https://www.youtube.com/embed/Ebr4GWrgIhE?rel=0&vq=hd1080"
                      allow="autoplay; encrypted-media" 
                      allowFullScreen=""
                    ></iframe>
                  </div>

                </div>
              </div>
              <div className="col-2">
                <div className="side-img flex flex-sb">
                  <img src={img1} alt="standard acrylic" />
                  <img src={img2} alt="black gel resin" />
                  <img src={img3} alt="carbon gel resin" />
                  <img src={img4} alt="laser 4d" />
                  <img src={img5} alt="standard metal" />
                  <img src={img6} alt="vintage metal" />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="plate-warning">
              <img src={warning} alt="" className="w-100" />
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home