import { useEffect, useState } from 'react';

const useForm = (callback, inputs, requiredfields = [] ) => {
  // ** State
  const [values, setValues] = useState(inputs);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line
  }, [errors] );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: '' });   // Remove error on change
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
        
    let error = {};
    for (const [key, value] of Object.entries(values)) {
      if(requiredfields.includes(key)) {
        var str = key.replace('_', ' ');
        var newstr = str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
  
        if(!value) {
          error = { ...error, [key]: 'The ' + [newstr] + ' field is required.' };   // create errors list
        }
      }
    }

    setErrors(error);   // set error on submit
    setIsSubmitting(true);
  };

  const handleValidate = (e) => {
    e.preventDefault();

    let error = {};
    for (const [key, value] of Object.entries(values)) {
      if(requiredfields.includes(key)) {
        var str = key.replace('_', ' ');
        var newstr = str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

        if(value === "") {
          error = { ...error, [key]: 'The ' + [newstr] + ' field is required.' };   // create errors list
        }
      }
    }

    
    if(Object.keys(error).length === 0) {
      return true;
    }
    else {
      setErrors(error);   // set error on Validation
      return false;
    }
  }

  const handleErrors = (data) => {
    let error = {};
    for (const [key, value] of Object.entries(data)) {
      error = { ...error, [key]: value[0] };   // create errors list from server
    }

    setErrors(error);   // set error on submit
    setIsSubmitting(false);
  }

  return { handleChange, handleSubmit, setValues, values, setErrors, errors, handleErrors, handleValidate };
};

export default useForm;