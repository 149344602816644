import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { FaAngleLeft, FaAngleRight, FaClipboardList, FaCreditCard, FaPlusCircle, FaShippingFast, FaShoppingCart, FaTools, FaUser } from "react-icons/fa"
import { TiShoppingCart } from "react-icons/ti"

import jwt from '../../Auth/jwt'
import CartItems from "./CartItems"
import { Spinner } from "../../Components"

const Cart = () => {

  const [cartItems, setCartItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    jwt.get('/cart').then(response => {
      if(response.status === 200) {
        setCartItems(response.data)
        jwt.get('/fields').then(res => {
          if(res.status === 200) {
            setData(res.data)
            setIsLoading(false)
          }
        })
      }
    })
  }, [])

  const removeItem = (itemId) => {
    jwt.delete(`/cart/remove/${itemId}`).then(res => {
      if(res.status === 200)
        setCartItems(res.data)
    })
  }

  // ** Function to extract total of all items
  const getTotal = ( para ) => {
    let total = 0;
    if(para === 'total') {
      cartItems.map(item => total = total + (item.price_now * item.front_qty) + (item.price_now * item.rear_qty) );
    }
    else if(para === 'sub') {
      cartItems.map(item => total = total + (item.price_was * item.front_qty) + (item.price_was * item.rear_qty) );
    }
    else if(para === 'discount') {
      let sb = 0, t = 0
      cartItems.map(item => {
        sb = sb + (item.price_was * item.front_qty) + (item.price_was * item.rear_qty);
        t = t + (item.price_now * item.front_qty) + (item.price_now * item.rear_qty);
      });
      total = sb - t;
    }
    return parseFloat(total).toFixed(2);
  }

  return (
    <div className="checkout">
      <div className="container">
        <div className="steps">
          <div className="flex-w mt-2">
            <p className="step mb-2 active"><FaShoppingCart /><span>Shopping cart</span></p>
            <p className="step mb-2"><FaTools /><span>Accessories</span></p>
            <p className="step mb-2"><FaUser /><span>Customer</span></p>
            <p className="step mb-2"><FaShippingFast /><span>Delivery</span></p>
            <p className="step mb-2"><FaClipboardList /><span>Summary</span></p>
            <p className="step mb-2"><FaCreditCard /><span>Payment</span></p>
          </div>
        </div>
        <div className="actions">
          <div className="flex-w flex-sb">
            <Link to="/design-online" className="btn btn-white m-1" ><FaPlusCircle />Add More<FaAngleLeft className="angle" /></Link>
            <Link to="/checkout" className="btn btn-white m-1" ><FaShoppingCart />Checkout<FaAngleRight className="angle" /></Link>
          </div>
        </div>

        {isLoading ? <Spinner message="Fetching Cart Items..." /> :
          // START CART
          <div className="cart m-1">
            {cartItems.length > 0 ? cartItems.map(item => (
              <CartItems item={item} removeItem={removeItem} data={data} key={item.id} />
            )) : (
              <div className="cart-container">
                <div className="cart-empty flex">
                  <div className="flex flex-c m-2">
                    <TiShoppingCart />
                    <h2>Your Shopping cart is currently empty! <br />Click "Add More" to add item to your cart.</h2>
                  </div>
                </div>
              </div>
            )}
          </div>
          // END CART
        }

        {cartItems.length > 0 ? (
          <div className="totals mb-2 rd">
            <div className="flex flex-je w-100">
              <div className="t-head">
                Totals
              </div>
            </div>
            <div className="t-body flex flex-sb w-100">
              <div className="price flex flex-e flex-je pd w-100">
                <div className="fields pr-fields">
                  <div className="field flex-w">
                    <div className="name"><span>Sub Total</span></div>
                    <div className="values">£{getTotal('sub')}</div>
                  </div>
                  <div className="field flex">
                    <div className="name"><span>Discount</span></div>
                    <div className="values">-£{getTotal('discount')}</div>
                  </div>
                  <div className="field flex" style={{ color: 'yellow' }}>
                    <div className="name"><span>Total</span></div>
                    <div className="values">£{getTotal('total')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>) : null 
        }

        <div className="actions mb-2">
          <div className="flex-w flex-sb">
            <Link to="/design-online" className="btn btn-white m-1" ><FaPlusCircle />Add More<FaAngleLeft className="angle" /></Link>
            <Link to="/checkout" className="btn btn-white m-1" ><FaShoppingCart />Checkout<FaAngleRight className="angle" /></Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Cart