import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { MdFormatSize, MdOutlineBorderStyle } from 'react-icons/md'
import { FiEdit } from "react-icons/fi"
import { HiDownload } from "react-icons/hi"
import { GiResize } from "react-icons/gi"
import { BiColorFill } from "react-icons/bi"
import { FaEdit, FaRoad, FaShoppingCart, FaTrashAlt } from "react-icons/fa"
import DesignPreview from '../Designer/DesignPreview'

import { setFont } from '../../Utils/Utils'

const CartItems = ({ type, item, removeItem, data }) => {

  if(item.font !== '') {
    const fnt = data.fonts.find(m => m.name === item.font);
    setFont(fnt, item.font + "NUM", item.font + "NUM");
  }
  
  if(item.footer_font !== null) {
    const fofnt = data.fonts.find(m => m.name === item.footer_font);
    setFont(fofnt, item.footer_font + "FOOT", item.footer_font + "FOOT");
  }

  const node = useRef(null)
  const [ratio, setRatio] = useState({
    width: 0,
    HeightF: 0,
    HeightR: 0
  })
  
  const values = {
    type: item.type,
    registration: item.registration,
    front_size: item.front_size,
    rear_size: item.rear_size,
    font: item.font,
    front_qty: item.front_qty === null ? 0 : item.front_qty,
    rear_qty: item.rear_qty === null ? 0 : item.rear_qty,
    border: item.border === null ? '' : item.border,
    badge: item.badge === null ? '' : item.badge,
    footer_text: item.footer_text === null ? '' : item.footer_text,
    footer_font: item.footer_font === null ? '' : item.footer_font,
    footer_color: item.footer_color === null ? '' : item.footer_color,
    legal_details: item.legal_details,
    price_was: item.price_was,
    price_now: item.price_now
  };

  const bdg = data.badges.find(item => item.name === values.badge)
  const brColor = data.colors.find(item => item.name === values.border)

  const prwas = parseFloat((values.front_qty * values.price_was)+(values.rear_qty * values.price_was)).toFixed(2);
  const prnow = parseFloat((values.front_qty * values.price_now)+(values.rear_qty * values.price_now)).toFixed(2);
  
  const icons = {
    registration: <FiEdit />,
    front_size: <GiResize />,
    rear_size: <GiResize />,
    footer_text: <HiDownload />,
    font: <MdFormatSize />,
    border: <MdOutlineBorderStyle />,
    badge: <MdOutlineBorderStyle />,
    front_qty: <MdOutlineBorderStyle />,
    rear_qty: <MdOutlineBorderStyle />,
    legal_details: <FaRoad />,
    footer_font: <MdFormatSize />,
    footer_color: <BiColorFill />,
  }

  useEffect(() => {
    if(node.current === null)
      return;

    let fw = values.front_size.substring(0, values.front_size.indexOf('x'));
    let fh = values.front_size.substring(values.front_size.indexOf('x') + 1, values.front_size.indexOf('m'));

    let rw = values.rear_size.substring(0, values.rear_size.indexOf('x'));
    let rh = values.rear_size.substring(values.rear_size.indexOf('x') + 1, values.rear_size.indexOf('m'));

    let Fheight = node.current.clientWidth / (fw / fh)
    let Rheight = node.current.clientWidth / (rw / rh)

    setRatio({ width: node.current.clientWidth, HeightF: Fheight ? Fheight + 70 : 190, HeightR: Rheight ? Rheight + 70 : 190 })
  }, [])

  return (
    <div className="item mb-2">
      <div className="item-header flex-w flex-sb">
        <div className="flex flex-c m-1">
          <FaShoppingCart size={25} className="mr-2"/>
          <h3>{values.type} Number Plates</h3>
        </div>
        {type !== 'View' ? (
          <div className="actions flex-w flex-c mt-1">
            <Link className="btn btn-white mr-2 mb-1" to={`/design-online/edit/${item.type}-${item.id}`} ><FaEdit />Edit Item</Link>
            <button to="/design-online" className="btn btn-white mb-1" onClick={() => removeItem(item.id)}>
              <FaTrashAlt />Remove Item
            </button>
          </div>
        ) : null}
      </div>
      <div className="item-body flex-w">
        <div className="col-8">
          <div className="flex pd">
            <div className="fields">
              {Object.keys(values).map(key => {
                if(key === 'type' || key === 'price_was' || key === 'price_now') {
                  return null
                }
                else if(values[key] === '') {
                  return null
                }

                let str = key.replace('_', ' ');
                let newstr = str.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

                return (
                  <div className="field flex-w" key={key} >
                    <div className="name">
                      {icons[key]}
                      <span>{newstr}:</span>
                    </div>
                    <div className={`values${key === 'registration' ? ' upper' : ''}`}>
                      {values[key]}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="price flex flex-e flex-je pd">
            <div className="fields pr-fields">
              <div className="field flex-w">
                <div className="name"><span>Price Was</span></div>
                <div className="values">£{prwas}</div>
              </div>
              <div className="field flex-w">
                <div className="name"><span>Discount</span></div>
                <div className="values">-£{parseFloat(prwas - prnow).toFixed(2)}</div>
              </div>
              <div className="field flex-w" style={{ color: 'yellow' }}>
                <div className="name"><span>Price Now</span></div>
                <div className="values">£{prnow}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item-preview">
        <div className="preview flex-w">
          {values.front_qty > 0 ?
            <div ref={node} className="col-5">
              <DesignPreview 
                values={values}
                width={ratio.width}
                badge={bdg ? bdg : { value: '', image: null}}
                brColor={brColor ? brColor.value : ''}
                height={ratio.HeightF}
                color="white"
                fontFmaily={item.font + "NUM"}
                fontFmailyfooter={item.footer_font + "FOOT"}
                />
            </div>
          : null }
          {values.rear_qty > 0 ?
            <div className="col-5">
              <DesignPreview 
                values={values}
                width={ratio.width}
                brColor={brColor ? brColor.value : ''}
                badge={bdg ? bdg : { value: '', image: null}}
                height={ratio.HeightR}
                color="yellow"
                fontFmaily={item.font + "NUM"}
                fontFmailyfooter={item.footer_font + "FOOT"}
                />
            </div>
          : null }
        </div>
      </div>
    </div>
  )
}

export default CartItems