import { Footer, Header } from "../../Components"
import Checkout from "./Checkout"

const CheckoutBase = () => {
  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <Checkout />
        <Footer />
      </div>
    </div>
  )
}

export default CheckoutBase