import axios from 'axios';

axios.defaults.withCredentials = true;

class Jwt {

  post(url, ...args) {
    return axios.post(`https://boostremap.co.uk/public/api${url}`, ...args);
  }

  put(url, ...args) {
    return axios.put(`https://boostremap.co.uk/public/api${url}`, ...args);
  }

  patch(url, ...args) {
    return axios.patch(`https://boostremap.co.uk/public/api${url}`, ...args);
  }
  
  get(url) {
    return axios.get(`https://boostremap.co.uk/public/api${url}`);
  }

  delete(url) {
    return axios.delete(`https://boostremap.co.uk/public/api${url}`);
  }

}


export default new Jwt()