import img from "../../Assets/review_quotes.png"
import img2 from "../../Assets/review_trustedshops.png"
import img3 from "../../Assets/designer_discount.png"

import { HiBadgeCheck } from "react-icons/hi"
import { FaPiggyBank } from "react-icons/fa"
import { BsShieldFillCheck } from "react-icons/bs"
import { HiDocumentMinus } from "react-icons/hi2"

import DesignerBase from "./DesignerBase"
import { Footer, Header } from "../../Components"

const DesignOnline = () => {
  return (
    <div className="wrapper">
      <div className="view-container">
        <Header />
        <div className="designer">
          <div className="col">
            <div className="ftur flex-w flex-sb">
              <div className="feature"><img src={img3}/><span>25% Off All Number Plates</span></div>
              <div className="feature"><FaPiggyBank /><span>14 Day Money Back Guarantee</span></div>
              <div className="feature"><HiBadgeCheck /><span>3 Year Warranty</span></div>
              <div className="feature"><BsShieldFillCheck /><span>Quality Guaranteed</span></div>
              <div className="feature"><HiDocumentMinus /><span>No Documents Required</span></div>
            </div>
          </div>
          <section>
            <DesignerBase />
          </section>
          <section>
            <div className="response flex-w flex-sb">
              <div className="icon">
                <img src={img} alt="" />
              </div>
              <div className="msg">
                <div className="titles flex-w flex-sb">
                  <h3>Over 24,000 Verified Reviews...</h3>
                  <h3>What our customers say...</h3>
                </div>
                <div className="desc">
                  <span>Excellent service from start to finish. DPD arrived next day. Fixings were handy too. Thanks PFC</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default DesignOnline