import { useNavigate } from 'react-router-dom'

import useForm from '../../Utils/Hooks/useForm'

import jwt from '../../Auth/jwt'
import Designer from './Designer'

const DesignerBase = () => {

  const navigate = useNavigate()

  const defaultValues = {
    type: '',
    registration: '',
    front_size: '',
    rear_size: '',
    font: '',
    border: '',
    badge: '',
    front_qty: 1,
    rear_qty: 1,
    legal_details: 'Yes',
    footer_text: '',
    footer_font: '',
    footer_color: 'Black',
    price_was: 0,
    price_now: 0,
    image: ''
  };

  const addCart = () => {
    let val = {...values}
    if(val.footer_text === '') {
      val = {...val, footer_font: '', footer_color: ''}
    }

    jwt.post('/cart/add', val).then(res => {
      if(res.status === 200) {
        navigate('/cart')
      }
      else if(res.status === 203) {
        if(Object.keys(res.data.errors).length) {
          handleErrors(res.data.errors);
        }
      }
    }).catch(err => {
      if(err.response) {
        if(err.response.status === 422) {
          handleErrors(err.response.data.errors);
        }
        else
          console.log(err);
      }
    })
  }

  const { handleChange, handleSubmit, setValues, values, setErrors, errors, handleErrors } = 
  useForm( addCart, defaultValues, [ 'registration' ]);

  return (
    <Designer
      type="New"
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
      values={values}
      setValues={setValues}
      errors={errors}
      setErrors={setErrors}
    />
  )
}

export default DesignerBase