import { useEffect, useRef, useState } from 'react'

import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver'

import { BsZoomIn } from 'react-icons/bs'
import { FaDownload  } from 'react-icons/fa'

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  let blob = new Blob([ab], {type: mimeString});
  return blob;

}

const DesignPreview = ({ values, width, height, color, badge, brColor, ftColor, fontFmaily, fontFmailyfooter, bkcolor, fntcolor }) => {

  const node = useRef(null)
  const [size, setSize] = useState(11)

  const prev = {
    backgroundImage: `url(${window.location.origin}/static/media/images/DefaultBackground.jpg)`,
    backgroundPosition: '0% 0%',
    backgroundSize: '160%',
  }

  const getFontSize = ( ) => {
    const { content, fonts } = node
    const baseSize = 11
    let of = 0;

    of = ((fonts.offsetWidth - content.offsetWidth + 100) / window.innerWidth) * 100
    
    const fontSize = (of / baseSize)
    setSize(prev => { return (prev - fontSize) })
  }
  
  useEffect(() => {
    if(node.current === null || node.figure === null || node.content === null || node.fonts === null)
      return;

    if (node.fonts.scrollWidth >= node.content.scrollWidth) {
      getFontSize();
      return;
    }

    if( node.fonts.scrollWidth < node.content.scrollWidth - 100 && size !== 11) {
      setSize(11)
      return;
    }

    node.fonts.style.visibility = "visible";

    if(badge.image === null) {
      domtoimage.toSvg(node.current)
      .then (function (dataUrl) {
        node.figure.style.backgroundImage = `url('${dataUrl}')`
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      });

      return;
    }
    
    if(node.canvas === null)
      return;

    var c = node.canvas;
    var ctx = c.getContext("2d");
    ctx.clearRect(0, 0, node.canvas.width, node.canvas.height)

    const img = new Image(); // Create new img element
    img.src = `/static/media/badges/${badge.image}`; // Set source path
    
    img.onload = function() {
      ctx.drawImage(img, 0, 0, img.width,    img.height,     // source rectangle
                        0, 0, node.canvas.width, node.canvas.height);
      domtoimage.toSvg(node.current)
      .then (function (dataUrl) {
        node.figure.style.backgroundImage = `url('${dataUrl}')`
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      });
    };

  }, [size, values, width, badge.image])

  const savePng = () => {

    node.mark.style.display = `flex`;

    domtoimage.toPng(node.current)
      .then (function (dataUrl) {
          node.mark.style.display = `none`;
          var blob = new Blob([dataURItoBlob(dataUrl)]);
          FileSaver.saveAs(blob, "platesforcars.jpg");
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error)
      }); 
  }  

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left - window.pageXOffset) / width * 100
    const y = (e.pageY - top - window.pageYOffset) / height * 100

    node.figure.style.backgroundPosition = `${x}% ${y}%`
  }

  return (
    <>
      <div className="pre-container flex flex-jc">
        <img ref={div => node.img = div} src={`/static/media/badges/${badge.image}`} alt="" style={{ display: 'none' }}/>
        <div className="view" style={{ width: width, height: height }}>
          <figure ref={div => node.figure = div} id="prev-fig" onMouseMove={(e) => handleMouseMove(e)} style={prev}></figure>
          <div ref={node} className="prev">
            <div ref={div => node.mark = div} style={{ display: 'none' }} className="watermark flex-c flex-jc">
              <span>Plates For Cars</span>
            </div>
            <div className="prev-back"
              style={color === 'yellow' ?
              { backgroundImage: 'linear-gradient(#f4fb84, #e8f700)'} : { backgroundColor: color }}
            >
              <div 
                className="prev-content"
                style={{
                  borderColor: brColor,
                  justifyContent: values.badge !== '' ? 'center' : 'space-between',
                  backgroundColor: bkcolor
                }}
                >
                <div className="prev-badge flex flex-c flex-se" style={{ display: values.badge === '' ? 'none' : 'flex', height: '100%' }}>
                  {badge.image !== null ? <canvas ref={div => node.canvas = div}></canvas> : null}
                </div>
                <div 
                  ref={div => node.content = div}
                  className="reg-number"
                  style={{
                    width: `${(( values.badge ? width - 100 : width - 50 ) / window.innerWidth) * 100}vw`,
                    height: '100%'
                  }}
                >
                  <span 
                    ref={div => node.fonts = div} 
                    style={{ color: fntcolor, visibility: 'hidden', fontSize: `${size}vw`, lineHeight: `${height}px`, fontFamily: `${fontFmaily ? fontFmaily : "'PlateFont', 'Verdana'"}` }}
                    className="prev-reg"
                  >
                    {values.registration === '' ? 'YOUR REG' : values.registration.toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="prev-footer-txt" 
              style={{ backgroundColor: color, fontFamily: `${fontFmailyfooter ? fontFmailyfooter : "'FooterFont', 'Verdana'"}` }}>
                <span style={{ color: ftColor }} >{values.footer_text}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="prev-actions">
          <ul>
            <li><BsZoomIn size={20} color="white"/></li>
            <li>
              <button onClick={() => savePng()}>
                <FaDownload size={20} color="white" />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default DesignPreview