import { useEffect, useState } from "react";
import { FaShoppingBasket } from "react-icons/fa";
import { useParams } from "react-router-dom";
import jwt from "../../Auth/jwt";
import { Alert, NumberInput, Spinner } from "../../Components";

const ItemPreview = () => {

  const { id } = useParams();
  const [data, setData] = useState(null)
  const [qty, setQty] = useState(1)
  const [cart, setCart] = useState([])
  const [alert, setAlert] = useState({
    active: false,
    error: ''
  })

  useEffect(() => {
    jwt.get(`/shop-products/${id}`).then(res => {
      if(res.status === 200) {
        setData(res.data)
      }
    })
    jwt.get('/shop-cart').then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
    })
  }, [])

  const addCart = () => {
    jwt.post('/shop-cart/add', { product: data, qty: qty })
    .then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
      else if(res.status === 203) {
        setAlert({ active: true, error: res.data.message })
      }
    })
    .catch(err => {
      if(err.response.status === 422) {
        setAlert({ active: true, error: err.response.data.message })
      }
      else {
        console.log(err)
      }
    })
  }

  const removeCart = () => {
    jwt.delete(`/shop-cart/remove/${data.id}`)
    .then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
    })
  }

  if(data === null) return <Spinner message="Fetching Products..." />;

  const isInCart = cart.find(pro => pro.product.id === data.id);

  return (
    <>
      {alert.active &&
        <Alert icon="error" text={alert.error} func={() => {
          setAlert({ active: false, error: '' });
        }} />
      }
      <div className="shop m-2">
        <div className="shop-container shop-prev">
          <div className="flex-w">
            <div className="col-8">
              <img src={`/static/media/shop-procuts/${data.image}`} alt="" className="img-fluid" />
            </div>
            <div className="col-8">
              <div className="content">
                <h1 className="name">{data.name}</h1>
                <h1 className="mb-1 price">£{data.price_now}</h1>
                <NumberInput
                  setValue={v => {
                    setQty(v)
                  }}
                  max={data.qty}
                  min={1}
                  value={qty}
                />
                <div className="desc mt-2">
                  <p>{data.description}</p>
                </div>
                <button 
                  onClick={() => addCart()} 
                  className="hover-btn flex flex-c"
                  disabled={data.qty > 0 ? false : true}
                  {...(isInCart ? { onClick: () => removeCart() } : { onClick: () => addCart() })}
                >
                    <FaShoppingBasket /><span>{isInCart ? 'Remove from Cart' : 'Add To Cart'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemPreview