import { useNavigate } from 'react-router-dom'

import img from "../../Assets/review_quotes.png"
import img2 from "../../Assets/review_trustedshops.png"
import img3 from "../../Assets/designer_discount.png"

import { HiBadgeCheck } from "react-icons/hi"
import { FaPiggyBank } from "react-icons/fa"
import { BsShieldFillCheck } from "react-icons/bs"
import { HiDocumentMinus } from "react-icons/hi2"

import useForm from '../../Utils/Hooks/useForm'

import jwt from '../../Auth/jwt'
import Designer from '../Designer/Designer'

const CartEditDesigner = ({ data, id }) => {
  const navigate = useNavigate()

  const defaultValues = {
    type: data.type,
    registration: data.registration,
    front_size: data.front_size,
    rear_size: data.rear_size,
    font: data.font,
    front_qty: data.front_qty === null ? 0 : data.front_qty,
    rear_qty: data.rear_qty === null ? 0 : data.rear_qty,
    border: data.border === null ? '' : data.border,
    badge: data.badge === null ? '' : data.badge,
    footer_text: data.footer_text === null ? '' : data.footer_text,
    footer_font: data.footer_font === null ? '' : data.footer_font,
    footer_color: data.footer_color === null ? '' : data.footer_color,
    legal_details: data.legal_details,
    price_was: data.price_was,
    price_now: data.price_now
  };

  const updateCart = () => {
    jwt.put(`/cart/update/${id}`, values).then(res => {
      if(res.status === 200) {
        navigate('/cart')
      }
      else if(res.status === 203) {
        if(Object.keys(res.data.errors).length) {
          handleErrors(res.data.errors);
        }
      }
    }).catch(err => {
      if(err.response) {
        if(err.response.status === 422) {
          handleErrors(err.response.data.errors);
        }
        else
          console.log(err);
      }
    })
  }

  const { handleChange, handleSubmit, setValues, values, setErrors, errors, handleErrors } = 
  useForm( updateCart, defaultValues, [ 'registration' ]);

  return (
    <div className="design-edit">
      <div className="col">
        <div className="ftur flex-w flex-sb">
          <div className="feature"><img src={img3}/><span>25% Off All Number Plates</span></div>
          <div className="feature"><FaPiggyBank /><span>25% Off All Number Plates</span></div>
          <div className="feature"><HiBadgeCheck /><span>25% Off All Number Plates</span></div>
          <div className="feature"><BsShieldFillCheck /><span>25% Off All Number Plates</span></div>
          <div className="feature"><HiDocumentMinus /><span>25% Off All Number Plates</span></div>
        </div>
      </div>
      <section>
        <Designer
          type="edit"
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          defaultValues={defaultValues}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
      </section>
      <section>
        <div className="response flex-w flex-sb">
          <div className="icon">
            <img src={img} alt="" />
          </div>
          <div className="msg">
            <div className="titles flex-w flex-sb">
              <h3>Over 24,000 Verified Reviews...</h3>
              <h3>What our customers say...</h3>
            </div>
            <div className="desc">
              <span>Excellent service from start to finish. DPD arrived next day. Fixings were handy too. Thanks PFC</span>
            </div>
          </div>
          <div className="icon icon-right">
            <img src={img2} alt="" className="w-100" />
          </div>
        </div>
      </section>
    </div>
  )
}

export default CartEditDesigner