import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { BsBrushFill, BsCloudArrowDownFill } from 'react-icons/bs'
import { FaCamera, FaCartPlus, FaCog, FaShoppingCart } from 'react-icons/fa'

import { Alert, ConfirmAlert, Spinner } from '../../Components'
import DesignerForm from './DesignerForm'
import DesignPreview from './DesignPreview'

import jwt from '../../Auth/jwt'
import { setFont } from '../../Utils/Utils'
import SaveForm from './SaveForm'

const Designer = ({ type, defaultValues, handleChange, handleSubmit, values, setValues, errors, setErrors }) => {

  const node = useRef(null)

  const [ratio, setRatio] = useState({
    width: 0,
    HeightF: 0,
    HeightR: 0
  })
  const [regAlert, setRegAlert] = useState(false)
  const [alert, setAlert] = useState({
    active: false,
    state: 'warning',
    rowId: null,
    error: ''
  })
  const [data, setData] = useState(null)
  const [badge, setBadge] = useState({ 
    value: '',
    image: null,
  })
  const [bColor, setBColor] = useState('')
  const [fColor, setFColor] = useState('')
  const [saveAlert, setSaveAlert] = useState(false)
  const [saveditems, setSaveditems] = useState(null)
  const [selProduct, setSelProduct] = useState({})

  const deleteDesign = ( name ) => {
    jwt.delete(`/savedesign/remove/${name}`).then(res => {
      if(res.status === 200) {
        setSaveditems(res.data)
      }
    })
  }

  useEffect(() => {
    if(data === null)
      return

    if(type === 'edit' || type === 'Saved') {
      let bg = data.badges.find(item => item.name === defaultValues.badge)
      if(bg)
        setBadge({ value: bg.value !== null ? bg.value : '', image: bg.image })
        
      let bclr = data.colors.find(item => item.name === defaultValues.border)
      if(bclr)
        setBColor(bclr.value)

      let fclr = data.colors.find(item => item.name === defaultValues.footer_color)
      if(fclr)
        setFColor(fclr.value)
    }
  }, [data])


  useEffect(() => {
    jwt.get('/fields').then(res => {
      if(res.status === 200) {
        if(type === 'New') {
          let cat = res.data.categories.length > 0 ? res.data.categories[0] : '';
          let pro = '';
          if(cat  !== '') {
            pro = cat.products.length > 0 ? cat.products[0] : '';
          }
          setSelProduct(pro);
          setValues({ ...values,
            front_size: res.data.front_sizes.length > 0 ? res.data.front_sizes[0].name : '',
            rear_size: res.data.rear_sizes.length > 0 ? res.data.rear_sizes[0].name : '',
            font: res.data.fonts.length > 0 ? res.data.fonts[0].name : '',
            footer_font: res.data.footer_fonts.length > 0 ? res.data.footer_fonts[0].name : '',
            type: cat !== '' ? pro !== '' ? pro.name + ' ' + cat.name : '' : '',
            price_was: pro !== '' ? pro.price_was : 0,
            price_now: pro !== '' ? pro.price_now : 0,
          })

          if(res.data.fonts.length > 0) {
            const fnt = res.data.fonts.find(m => m.name === res.data.fonts[0].name);
            setFont(fnt, 'font-style', 'PlateFont');
  
            if(values.footer_font !== '') {
              const fofnt = res.data.fonts.find(m => m.name === res.data.fonts[0].name);
              setFont(fofnt, 'font-footer', 'FooterFont');
            }
          }
        }
        else {
          if(res.data.categories.length > 0) {
            res.data.categories.map((cat, i) => {
              if(cat.products.length > 0) {
                cat.products.map((item, index) => {
                 if(values.type === item.name + ' ' + cat.name) {
                  setSelProduct(item);
                 }
               })
              }
            })
          }
          setValues({ ...values, font: res.data.fonts.length > 0 ? res.data.fonts[0].name : 'Verdana' })
          if(res.data.fonts.length > 0) {
            const fnt = res.data.fonts.find(m => m.name === values.font);
            setFont(fnt, 'font-style', 'PlateFont');
  
            if(values.footer_font !== '') {
              const fofnt = res.data.fonts.find(m => m.name === values.footer_font);
              setFont(fofnt, 'font-footer', 'FooterFont');
            }
            document.fonts.ready.then(function () { // IF FONT LOADED
              setValues({ ...values, font: fnt.name })
            });
          }
        }
        setData(res.data)
      }
    })
    jwt.get('/savedesign').then(res => {
      if(res.status === 200) {
        setSaveditems(res.data);
      }
    })
  }, [])

  useEffect(() => {
    if(node.current === null)
      return;

    let fw = values.front_size.substring(0, values.front_size.indexOf('x'));
    let fh = values.front_size.substring(values.front_size.indexOf('x') + 1, values.front_size.indexOf('m'));

    let rw = values.rear_size.substring(0, values.rear_size.indexOf('x'));
    let rh = values.rear_size.substring(values.rear_size.indexOf('x') + 1, values.rear_size.indexOf('m'));

    let Fheight = node.current.clientWidth / (fw / fh)
    let Rheight = node.current.clientWidth / (rw / rh)

    setRatio({ width: node.current.clientWidth, HeightF: Fheight ? Fheight + 70 : 190, HeightR: Rheight ? Rheight + 70 : 190 })
  }, [values])

  if(data === null) return <Spinner message="Fetching fields data..." />;

  return (
    <div className="workstation">
      {regAlert &&
        <Alert icon="error" text="Registration required." func={() => setRegAlert(false)} />
      }
      <SaveForm setSaveditems={setSaveditems} setSaveAlter={setSaveAlert} saveAlert={saveAlert} data={values}  />
      <ConfirmAlert
        alert={alert}
        setAlert={setAlert}
        classes=' designer-confirm'
        handleSubmit={handleSubmit}
        confirmTitle="Add Registration Space?"
        btntext="NO...I am happy with the spacing!"
        hideConfirm={true}
        cbtntext="YES...I would like to add spacing!"
        text={{
          Confirm: 'You haven\'t entered a space in your registration, spacing is required for road legal plates, did you want to amend your registration and add a space?',
        }}
      />
      <div className="flex-w flex-row">
        <div className="col-3">
          <div className="options mb-1">
            <ul>
              {data.categories.length > 0 ? data.categories.map((cat, i) => (
                <React.Fragment key={i}>
                  <li className="options-title">{cat.name} Options</li>
                  {cat.products.length > 0 ? cat.products.map((item, index) => (
                    <li key={index} className="flex">
                      <button
                        className={`${values.type === item.name + ' ' + cat.name ? 'active' : ''}`}
                        onClick={() => {
                          setSelProduct(item)
                          setValues({ ...values,
                            type: item.name + ' ' + cat.name,
                            price_was: item.price_was,
                            price_now: item.price_now
                          })
                        }}
                      >{item.name}</button>
                      <div className="type-prev flex flex-c">
                        <FaCamera color="white"  />
                        <span>
                          <img src={`/static/media/products/${item.image}`} alt="sample" />
                        </span>
                      </div>
                    </li>
                  )) : null}
                </React.Fragment>
              )) : null }
            </ul>
          </div>
        </div>
        <div className="col-4">
          <div className="fields">
            <DesignerForm
              handleSubmit={handleSubmit}
              values={values}
              errors={errors}
              data={data}
              setErrors={setErrors}
              handleChange={handleChange}
              setValues={setValues}
              selProduct={selProduct}
              setBadge={setBadge}
              setFColor={setFColor}
              setBColor={setBColor}
            />
          </div>
          <div className="price flex flex-c flex-je m-1 mr-1">
            <div className="pr-was">
              WAS £{
                parseFloat((values.front_qty * values.price_was)+(values.rear_qty * values.price_was)).toFixed(2)
              }
            </div>
            <div className="pr-now ml-2">
              NOW £{parseFloat((values.front_qty * values.price_now)+(values.rear_qty * values.price_now)).toFixed(2)}
            </div>
          </div>
          <div className="actions">
            <ul className="flex-w flex-c flex-je">
              <li><button onClick={() => window.location.reload()} className="design-action"><FaCog /> Reset design</button></li>
              <li><button onClick={() => setSaveAlert(true)} className="design-action"><BsCloudArrowDownFill /> Save design</button></li>
              <li><Link className="design-action" to="/cart"><FaShoppingCart /> View Cart</Link></li>
              {type === 'edit' ? 
              <li>
                <button
                  disabled={JSON.stringify(values) === JSON.stringify(defaultValues) ? true : false}
                  onClick={(e) => {
                    if(values.registration !== '') {
                      if(values.registration.indexOf(' ') <= -1) {
                        setAlert({ ...alert, active: true })
                        return
                      }
                    }

                    if(values.registration === '') {
                      setRegAlert(true);
                    }
                    else {

                      handleSubmit(e)
                    }
                  }}
                  className="design-action"
              >
                <BsBrushFill /> Update design</button></li> 
              : (
                <li>
                  <button 
                    onClick={(e) => {
                      if(values.registration !== '') {
                        if(values.registration.indexOf(' ') <= -1) {
                          setAlert({ ...alert, active: true })
                          return
                        }
                      }
                      
                      if(values.registration === '') {
                        setRegAlert(true);
                      }
                      else {
                        
                        handleSubmit(e)
                      }                    
                    }}
                    className="design-action"
                  ><FaCartPlus /> Add to Cart</button></li>                  
                )}
            </ul>
          </div>
          <div className="saved-items flex flex-c flex-je m-1 mr-1">
            {saveditems !== null ? saveditems.length > 0 ? saveditems.map((item, index) => (
              <div className="saved-items-link ml-2 flex flex-c" key={index} >
                <button onClick={() => deleteDesign(item.name)}>x</button>
                <Link to={`/design-online/saved/edit/${item.name}`} >{item.name} - Saved Design</Link>
              </div>
            )) : null : null}
          </div>
        </div>
      </div>
      <div className="preview flex-w">
      {values.front_qty > 0 ? 
        <div ref={node} className="col-5">
          <DesignPreview
            values={values}
            badge={badge}
            setValues={setValues}
            width={ratio.width}
            height={ratio.HeightF}
            brColor={bColor}
            ftColor={fColor}
            bkcolor={selProduct.background_value}
            fntcolor={selProduct.font_color}
            color="white"
            />
        </div>
        : null }
        {values.rear_qty > 0 ? 
          <div className="col-5">
            <DesignPreview
              values={values}
              badge={badge}
              setValues={setValues}
              width={ratio.width}
              height={ratio.HeightR}
              color={selProduct.background_value === null ? "yellow" : "white"}
              brColor={bColor}
              ftColor={fColor}
              bkcolor={selProduct.background_value}
              fntcolor={selProduct.font_color}
              />
          </div>
        : null }
      </div>
    </div>
  )
}

export default Designer