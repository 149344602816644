import { useEffect, useState } from "react";
import { FaShoppingBasket } from "react-icons/fa";
import { Link } from "react-router-dom";
import jwt from "../../Auth/jwt";
import { Alert, Spinner } from "../../Components";

const Shop = () => {

  const [data, setData] = useState(null)
  const [cart, setCart] = useState([])
  const [alert, setAlert] = useState({
    active: false,
    error: ''
  })

  useEffect(() => {
    jwt.get('/shop-products').then(res => {
      if(res.status === 200) {
        setData(res.data)
      }
    })
    jwt.get('/shop-cart').then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
    })
  }, [])

  const addCart = (e, item) => {
    e.preventDefault();
    jwt.post('/shop-cart/add', { product: item, qty: 1 })
    .then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
      else if(res.status === 203) {
        setAlert({ active: true, error: res.data.message })
      }
    })
    .catch(err => {
      if(err.response.status === 422) {
        setAlert({ active: true, error: err.response.data.message })
      }
      else {
        console.log(err)
      }
    })
  }

  const removeCart = (e, id) => {
    e.preventDefault();
    jwt.delete(`/shop-cart/remove/${id}`)
    .then(res => {
      if(res.status === 200) {
        setCart(res.data)
      }
    })
  }

  if(data === null) return <Spinner message="Fetching Products..." />;

  return (
    <>
      {alert.active &&
        <Alert icon="error" text={alert.error} func={() => {
          setAlert({ active: false, error: '' });
        }} />
      }
      <div className="shop m-2">
        <div className="shop-container">
          <div className="shop-header m-2 flex flex-sb">
            <h2>Showing {data.length} results</h2>
            <Link to="/checkout" >Checkout</Link>
          </div>
          <div className="flex-w">
            {data.length > 0 ? data.map((item, index) => {

              const isInCart = cart.find(pro => pro.product.id === item.id);

              return (
              <div className="col-13" key={index}>
                <div className="pro-container">
                  <Link to={`/shop/${item.id}`}>
                    <div className="thumb flex flex-c">
                      <div className="price">£{item.price_now}</div>
                      <img src={`/static/media/shop-procuts/${item.image}`} alt="" className="img-fluid" />
                      <button 
                        className="hover-btn flex flex-c"
                        disabled={item.qty > 0 ? false : true}
                        {...(isInCart ? { onClick: (e) => removeCart(e, item.id) } : { onClick: (e) => addCart(e, item) })}
                      >
                          <FaShoppingBasket /><span>{isInCart ? 'Remove from Cart' : 'Add To Cart'}</span>
                      </button>
                    </div>
                    <h2 className="name">{item.name}</h2>
                  </Link>
                </div>
              </div>
            )})
            : null }
          </div>
        </div>
      </div>
    </>
  )
}

export default Shop