import { FiMinus, FiPlus } from "react-icons/fi";

const NumberInput = ({ value, setValue, min, max }) => {

  return (
    <div className="num-input-group flex flex-c radius">
      <button 
        className="minus" 
        onClick={(e) => {
          e.preventDefault();
          setValue(prev => 
            (prev > min)
            ? (+prev - 1).toString()
            : prev )
        }}
      ><FiMinus /></button>
      <input
        className="form-control num-input"
        value={value}
        onChange={(e) => {
          setValue(prev => { return e.target.value.toString() })
        }}
        type="number"
        placeholder="0"
        onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
      />
      <button 
        className="plus" 
        onClick={(e) => {
          e.preventDefault();
          setValue(prev => 
            (prev < max)
            ? (+prev + 1).toString()
            : prev )
        }}
      ><FiPlus /></button>
    </div>
  )
}

export default NumberInput