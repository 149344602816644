import { useEffect, useState } from "react"
import { Link, useNavigate } from 'react-router-dom'

import { FaAngleLeft, FaAngleRight, FaClipboardList, FaCreditCard, FaShippingFast, FaShoppingCart, FaTools, FaUser } from "react-icons/fa"

import Customer from "./Customer"
import useForm from '../../Utils/Hooks/useForm'
import Delivery from "./Delivery"

import jwt from '../../Auth/jwt'
import Summary from "./Summary"
import Accessories from "./Accessories"
import { Alert } from "../../Components"
import ShippingDetails from "./ShippingDetails"

const Checkout = () => {

  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const [deliveries, setDeliveries] = useState(null)
  const [alert, setAlert] = useState(false)
  const [sameDetails, setSameDetails] = useState(true)
  const [empty, setEmpty] = useState(true)
  const [alertError, setAlertError] = useState({
    active: false,
    error: ''
  })

  const defaultValues = {
    full_name: '',
    company: '',
    email: '',
    mobile: '',
    address: '',
    city: '',
    country: '',
    postcode: '',
    delivery_type: '',
    vat_registered: false,
    vat_number: '',
  };

  const order = () => {
    jwt.post('/order', values).then(res => {
      if(res.status === 201) {
        if(sameDetails) {
          navigate(`/payment/${res.data.id}`)
        }
        else {
          let val = { ...values2, delivery_type: values.delivery_type}
          jwt.post(`/orders-shipping/${res.data.id}`, val)
          .then(response => {
            console.log(response)
            if(response.status === 201) {
              navigate(`/payment/${res.data.id}`)
            }
          })
        }
      }
      else {
        setAlert(true);
      }
    })
    .catch(err => {
      if(err.response.status === 422) {
        setAlert(true);
      }
      else
        console.log(err);
    })
  }

  const { handleChange, handleSubmit, setValues, values, errors, setErrors, handleValidate } = 
  useForm( order, defaultValues, [ 
    'full_name',
    'email',
    'mobile',
    'address',
    'city',
    'country',
    'postcode',
  ]);

  const { handleChange: handleChange2, 
    handleSubmit: handleSubmit2, 
    setValues: setValues2, 
    values: values2, 
    errors: errors2, 
    setErrors: setErrors2, 
    handleValidate: handleValidate2
  } = 
  useForm( order, defaultValues, [ 
    'full_name',
    'email',
    'mobile',
    'address',
    'city',
    'country',
    'postcode',
  ]);

  const handleRadioChange = (e) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });
  }

  useEffect(() => {
    if(deliveries !== null) {
      setDeliveries(null)
    }
  }, [values.country, values2.country])

  useEffect(() => {
    jwt.get('/cart-check').then(res => {
      if(res.status === 200) {
        if(res.data.length > 0) {
          setEmpty(false)
        }
      }
    })
  }, [])

  const getDelivery = () => {
    jwt.get(`/shipping/${sameDetails ? values.country : values2.country}`)
    .then(res => {
      if(res.status === 200) {
        setDeliveries(res.data)
      }
      else if(res.status === 203) {
        if(res.data.errors.shipping_charge) {
          setDeliveries(res.data.errors.shipping_charge[0])
        }
      }
    })
  }

  return (
    <div className="checkout">
      {alert &&
        <Alert icon="error" text="Order not placed successfully!!!" func={() => {
          setStep(1);
          setAlert(false);
        }} />
      }
      {alertError.active &&
        <Alert icon="error" text={alertError.error} func={() => {
          setAlertError({ active: false, error: '' });
        }} />
      }
      <div className="container">
        <div className="steps">
          <div className="flex-w mt-2">
            <p className="step mb-2 active done"><FaShoppingCart /><span>Shopping cart</span></p>
            <p className={`step mb-2${step === 0 ? ' active' : step > 0 ? ' done' : ''}`}><FaTools /><span>Accessories</span></p>
            <p className={`step mb-2${step === 1 ? ' active' : step > 1 ? ' done' : ''}`}><FaUser /><span>Customer</span></p>
            <p className={`step mb-2${step === 2 ? ' active' : step > 2 ? ' done' : ''}`}><FaShippingFast /><span>Delivery</span></p>
            <p className={`step mb-2${step === 3 ? ' active' : step > 3 ? ' done' : ''}`}><FaClipboardList /><span>Summary</span></p>
            <p className={`step mb-2${step === 4 ? ' active' : step > 4 ? ' done' : ''}`}><FaCreditCard /><span>Payment</span></p>
          </div>
        </div>

        {step === 0 ?
          <Accessories setEmpty={setEmpty} />
        : step === 1 ?
          <div className="customer">
            <Customer
              handleChange={handleChange}
              handleRadioChange={handleRadioChange}
              setValues={setValues}
              values={values}
              setErrors={setErrors}
              errors={errors}
            />
            <div className="row mt-1">
              <div className="col-10">
                <div className="cust-billing mb-1">
                  Shipping Notification:
                </div>
              </div>
              <div className="col-10">
                <div className="form-group flex flex-c mb-1">
                  <input
                    className="check-control mr-1"
                    checked={sameDetails}
                    onChange={() => {
                      setSameDetails(!sameDetails)
                    }}
                    type="checkbox"
                    name="vat_registered"
                    id="vat_registered"
                  />
                  <label htmlFor="vat_registered">Are your shipping details the same as the billing details?</label>
                </div>
              </div>
            </div>
            {!sameDetails ? 
              <ShippingDetails
                handleChange={handleChange2}
                setValues={setValues2}
                values={values2}
                setErrors={setErrors2}
                errors={errors2}
              />
            : null }
          </div>
        : step === 2 ?
          <Delivery 
            handleChange={handleChange}
            values={values}
            errors={errors}
            getDelivery={getDelivery}
            data={deliveries}
          />
        : step === 3 ?
          <div className="customer">
            <Summary
              values={values}
              deliveries={deliveries}
            />
          </div>
        : null }

        <div className="flex flex-jc">
          <div className="step-actions mb-2 mt-2">
            {step === 0 ?
              <Link to="/cart" className="btn btn-primary mr-1 mb-1"><FaAngleLeft /><span>Back</span></Link> 
              : <button 
                onClick={() => setStep(prev => prev - 1)}
                className="btn btn-primary mr-1 mb-1"
              ><FaAngleLeft /><span>Back</span></button>
            }
            {step === 3 ? (
              <button onClick={(e) => handleSubmit(e)} className="btn btn-primary mb-1"><span>Confirm Order</span><FaAngleRight /></button>
            ) : (
              <button onClick={(e) => {
                if(step === 0 && empty) {
                  setAlertError({ active: true, error: 'Please Select at least one item your cart is empty.'})
                }
                else if(step === 1) {
                  if(handleValidate(e) || handleValidate2(e)) {
                    if(sameDetails)
                      setStep(prev => prev + 1)
                    else if(handleValidate2(e)) {
                      setStep(prev => prev + 1)
                    }
                  }
                }
                else if(step === 2 && values.delivery_type === '') {
                  setErrors(prev => { return { ...prev, delivery_type: 'Delivery option not selected!!!' }})
                }
                else {
                  if(step < 3) {
                    setStep(prev => prev + 1)
                  }
                }

              }} className="btn btn-primary mb-1"><span>Continue</span><FaAngleRight /></button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout