import { Footer, Header } from "../../Components"
import CartEdit from "./CartEdit"

const CartEditBase = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="view-container">
        <CartEdit />
        <Footer />
      </div>
    </div>
  )
}

export default CartEditBase