import { Routes, Route } from 'react-router-dom';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Wrapper from "./Container/Wrapper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

function App() {
  return (
    <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, currency: "GBP", }} >
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/*" element={ <Wrapper /> } />
        </Routes>
      </Elements>
    </PayPalScriptProvider>
  );
}

export default App;
