import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import jwt from '../../Auth/jwt'
import { Spinner } from '../../Components'
import SaveEditDesigner from './SaveEditDesigner'

const SaveEdit = () => {

  const { name } = useParams();

  const [data, setData] = useState(null)

  useEffect(() => {
    jwt.get(`/savedesign/${name}`).then(res => {
      if(res.status === 200) {
        setData(res.data)
      }
    })
  }, [])

  if(data === null) return <Spinner message="Fetching Plate Details..." />;

  return data.registration !== undefined ? (
    <SaveEditDesigner id={name} data={data} />
  ) : (
    <div className="error m-2" role="alert">
      <h4 className="error-text radius mb-1">Plate not found. You attempted to edit an item that does not exist. Perhaps it was deleted?</h4>
      <div className="error-text radius">
        Saved design with name: {name} doesn't exist.
      </div>
    </div>
  )
}

export default SaveEdit