import { useEffect } from "react"

import { FaAngleRight, FaBox, FaCalendarAlt, FaShippingFast } from "react-icons/fa"

import { Spinner } from "../../Components"

const Delivery = ({ values, handleChange, getDelivery, data, errors }) => {

  useEffect(() => {
    if(data === null) {
      getDelivery()
      return;
    }

    if(data.constructor !== Array) {
      getDelivery()
      return;
    }
  }, [])  
  
  const getDate = (day) => {
    const date = new Date()
    let dt = new Date(date.getFullYear(), date.getMonth(), date.getDate() + day);
    
    return (
      dt.toLocaleString('default', { weekday: 'long' }) + " " +
      dt.getDate().toString() + "th " + 
      dt.toLocaleString('default', { month: 'long' })
    );
  }

  if(data === null) return <Spinner message="Fetching Delivery Details..." />;

  if(data.constructor !== Array) return (
    <div className="error" role="alert">
      <h4 className="error-text radius">Delivery to your country is not available right now!!!!</h4>
    </div>
  ); 

  return (
    <div className="delivery">
      <div className="delv-header flex flex-sb flex-c">
        <span>Please select a {values.country} delivery option from either of the {data.length} below options...</span>
        {errors.delivery_type && <span className="error-msg">{errors.delivery_type}</span>}
      </div>
      <div className="delv-body m-1">
        <div className="options">
          {data.length > 0 ? data.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-6">
                <div className="group flex-w flex-c">
                  <input
                    className="radio-control mr-1"
                    checked={values.delivery_type === item.service ? true : false}
                    value={item.service}
                    onChange={handleChange}
                    type="radio"
                    name="delivery_type"
                    id="shipping"
                  />
                  <div className="service flex-w flex-sb flex-c m-1">
                    <FaShippingFast size={25} className="ml-1" />
                    <div className="service-name flex flex-c ml-1 mr-1"><FaBox size={13}/><span>{item.service}</span></div>
                    <p className="range ml-1 mr-1">{item.min_days}-{item.max_days} Days</p>
                    <p className="charge mr-1 ml-1">${item.shipping_charges}</p>
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="group flex-w flex-c">
                  <div className="date flex flex-c m-1">
                    <FaCalendarAlt size={23} />
                    <p className="ml-1">{getDate(item.min_days)}</p>
                  </div>
                  <FaAngleRight size={26} className="ml-1 mr-1" />
                  <div className="date flex flex-c m-1">
                    <FaCalendarAlt size={23} />
                    <p className="ml-1">{getDate(item.max_days)}</p>
                  </div>
                </div>
              </div>
            </div>
          )) : null }
        </div>
      </div>
    </div>
  )
}

export default Delivery