import { useState } from "react"
import { FiTrash } from "react-icons/fi"
import jwt from "../../Auth/jwt"
import { NumberInput } from "../../Components"
import AccessoryMultiple from "./AccessoryMultiple"

const AccessoriesItem = ({ item, kits, setKits }) => {

  let q = kits.find(k => k.id === item.id)
  const [qty, setQty] = useState(() => {
    if(q) {
      return +q.qty;
    }
    else {
      return 1;
    }
  })
  
  let isincart = false;
  kits.find(k => {
    if(k.id === item.id) {
      isincart = true;
    }
  });

  const addtoCart = () => {
    jwt.post('/kit/add', { id: item.id, name: item.name, qty: +qty, price: item.price * qty })
    .then(res => {
      if(res.status === 200) {
        console.log(res)
        setKits(res.data)
      }
    })
  }

  const removeKit = () => {
    jwt.delete(`/kits/remove/${item.id}`)
    .then(res => {
      if(res.status === 200) {
        setKits(res.data)
      }
    })
  }

  return (
    <div className="item mb-1">
      <div className="item-header">
        <h4>£{item.price} - {item.name}</h4>
      </div>
      <div className="item-body mt-1">
        <div className="flex-w">
          <div className="col-11">
            <div className="content flex">
              <div className="flex">
                {item.colors.length > 0 ? (
                  <AccessoryMultiple item={item} kits={kits} setKits={setKits} />
                )
                : (
                  <>
                    <div className="left flex-sb flex-w mr-2 mb-1">
                      <div className="name mb-1">
                        <p className="mb-1">Product</p>
                        <p className="item-name fix-lh">{item.name}</p>
                      </div>
                      <div className="plus mb-1">
                        <p className="mb-1">Actions</p>
                        <div className="flex fix-lh">
                          <button 
                            disabled={q ? q.qty === +qty ? true : false : false}
                            className="btn btn-primary" 
                            onClick={() => addtoCart()} >
                          Add to Cart</button>
                          {isincart ? <button className="btn btn-primary ml-1" onClick={() => removeKit()} ><FiTrash /></button> : null}
                        </div>
                      </div>
                    </div>
                    <div className="flex mb-1">
                      <div className="qty">
                        <p className="mb-1">Quantity</p>
                        <div className="fix-lh flex flex-c">
                          <NumberInput
                            setValue={v => {
                              setQty(v)
                            }}
                            max={item.qty}
                            min={1}
                            value={qty}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="des">
                {item.description}
              </div>
            </div>
          </div>
          <div className="col-11">
            <div className="image flex flex-jc flex-c">
              <img src={`/static/media/products/${item.image}`} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccessoriesItem